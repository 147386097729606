import React from 'react';

//libs
import {LSymbols, LLib, LWeb3} from '../../../../../libs/'

//components
import Modal from '../../../Modal'
import { Text, Button, Group, Link, InputTokenAmountConverted, CheckBox } from '../../../../Controls'

//css
import './ModalVaultStrategySettings.css'

//vars
var modal_vaultStratSet = null

class ModalVaultStrategySettings extends React.Component
{
    constructor(props)
	{   
		super(props)
		
		//init state
		this.state = 
		{
            show: props.show || false,
            autoConvertDust: false,
            autoCompoundBeforeDeposit: false,
            autoCompoundBeforeWithdraw: false,
            minRewardToDeposit: "0",				
            minAdditionalRewardToReward: "0",
            minDustToken0: "0",  
            minDustToken1: "0"			
		}
        this.vault = null
        this.strategy = null
        this.rewardToken = null
        this.depositToken = null
        this.additionalRewardToken = null   

        //refs
        this.refCheckBoxAutoDust = React.createRef()
        this.refCheckBoxAutoCompound_deposit = React.createRef()
        this.refCheckBoxAutoCompound_withdraw = React.createRef()
        this.refInputMinReward = React.createRef()
        this.refInputMinAdditionalReward = React.createRef()
        this.refInputMinDust0 = React.createRef()
        this.refInputMinDust1 = React.createRef()

		//vars
		modal_vaultStratSet = this
        this.checkUpdate_vaultStrategy = this.checkUpdate_vaultStrategy.bind(this)
	}

    componentDidMount()
	{	
        document.addEventListener('vaultStrategy_info', this.checkUpdate_vaultStrategy)
	}

    componentWillUnmount()
	{	
        document.removeEventListener('vaultStrategy_info', this.checkUpdate_vaultStrategy)
	}

    checkUpdate_vaultStrategy(_data)
    {
        if (LWeb3.checkEqualAddress(this.vault.strategy?.address, _data.detail.address))
        {
            this.refresh()
        }
    }

	static showModal(_vault)
	{
        modal_vaultStratSet.vault = _vault        
        modal_vaultStratSet.load()
		modal_vaultStratSet.setShow(true)
	}

	setShow(_show)
	{
		this.setState(
		{
			show: _show
		})
	}

    async load()
    {
        const vaultStrat = await this.vault.getStrategy()
        if (vaultStrat)
        {
            vaultStrat.reloadData().then(() => this.refresh())
        }
    }

	async onClick_refresh()
	{
        await this.load()
	}

    async onClick_save()
	{
        this.setShow(false)
        if (this.vault.strategy === null)
        {
            return
        }

        //auto actions
        if (this.state.autoConvertDust !== this.strategy.autoConvertDust
            || this.state.autoCompoundBeforeDeposit !== this.strategy.autoCompoundBeforeDeposit
            || this.state.autoCompoundBeforeWithdraw !== this.strategy.autoCompoundBeforeWithdraw)
        {
            await this.vault.strategy.setAutoActions(
                this.state.autoConvertDust,
                this.state.autoCompoundBeforeDeposit,
                this.state.autoCompoundBeforeWithdraw)
        }

        //min reward
        if (this.state.minRewardToDeposit !== this.strategy.minRewardToDeposit
            || this.state.minAdditionalRewardToReward !== this.strategy.minAdditionalRewardToReward)
        {
            await this.vault.strategy.setMinRewardAmount(
                this.state.minRewardToDeposit,
                this.state.minAdditionalRewardToReward)
        }

        //min dust
        if (this.state.minDustToken0 !== this.strategy.minDustToken0
            || this.state.minDustToken1 !== this.strategy.minDustToken1)
        {
            await this.vault.strategy.setMinDustAmount(
                this.state.minDustToken0,
                this.state.minDustToken1)
        }
	}

    onClick_upgrade()
    {
        this.vault.upgradeStrat()
    }

    onChange_value()
    {
        //dust
        if (this.refCheckBoxAutoDust.current !== null)
        {            
            this.setState(
            {
                autoConvertDust: this.refCheckBoxAutoDust.current.isChecked(),
                minDustToken0: window.chef.toBN(this.refInputMinDust0.current.getAsUint256()).toString(10),
                minDustToken1: window.chef.toBN(this.refInputMinDust1.current.getAsUint256()).toString(10)
            })
        }

        //reward
        this.setState(
        {
            autoCompoundBeforeDeposit: this.refCheckBoxAutoCompound_deposit.current.isChecked(),
            autoCompoundBeforeWithdraw: this.refCheckBoxAutoCompound_withdraw.current.isChecked(),
            minRewardToDeposit: window.chef.toBN(this.refInputMinReward.current.getAsUint256()).toString(10)
        })

        //additionalreward
        if (this.refInputMinAdditionalReward.current !== null)
        {
            this.setState(
            {
                minAdditionalRewardToReward: window.chef.toBN(this.refInputMinAdditionalReward.current.getAsUint256()).toString(10)
            })
        }
    }

    refresh()
    {
        if (this.vault.strategy === null)
        {
            return
        }

        //get data
        this.strategy = this.vault?.strategy
        this.rewardToken = window.chef.findToken(this.vault?.rewardTokenAddress)
        this.depositToken = window.chef.findToken(this.vault?.depositTokenAddress)
        this.additionalRewardToken = window.chef.findToken(this.strategy?.additionalRewardTokenAddress)         
        this.setState(
        {
            autoConvertDust: this.strategy.autoConvertDust,
            autoCompoundBeforeDeposit: this.strategy.autoCompoundBeforeDeposit,
            autoCompoundBeforeWithdraw: this.strategy.autoCompoundBeforeWithdraw,
            minRewardToDeposit: this.strategy.minRewardToDeposit,				
            minAdditionalRewardToReward: this.strategy.minAdditionalRewardToReward,
            minDustToken0: this.strategy.minDustToken0,  
            minDustToken1: this.strategy.minDustToken1	           
        })
    }

    renderSettingsCol(_text, _content, _className)
    {
        return (
            <Group className={"settingsCol " + (_className || "")}>
                <Text color="2">
                    {_text}
                </Text>
                {_content}
            </Group>
        )
    }

    renderSettingsRow(_text, _content, _className)
    {
        return (
            <Group className={"settingsRow " + (_className || "")}>
                <Text color="2">
                    {_text}
                </Text>
                {_content}
            </Group>
        )
    }

    renderDust()
    {
        if (this.depositToken === null
            || !this.depositToken.isLPToken())
        {
            return
        }

        const token0 = window.chef.findToken(this.depositToken.token0)
        const token1 = window.chef.findToken(this.depositToken.token1)
        return (
            <Group>
                {this.renderSettingsRow("Auto Convert Dust",
                    <CheckBox ref={this.refCheckBoxAutoDust}
                    checked={this.state.autoConvertDust}
                    onChange={() => this.onChange_value()} />)}

                <Group className="row">
                    {this.renderSettingsCol("Min Dust Token0",
                        <InputTokenAmountConverted
                            ref={this.refInputMinDust0}
                            token={token0}
                            value={this.state.minDustToken0}
                            onChange={() => this.onChange_value()} />,
                        "col-6")}
                    {this.renderSettingsCol("Min Dust Token1",
                        <InputTokenAmountConverted
                            ref={this.refInputMinDust1}
                            token={token1}
                            value={this.state.minDustToken1}
                            onChange={() => this.onChange_value()} />,
                        "col-6")}
                </Group>
            </Group>
        )

    }

    renderMinReward()
    {
        if (this.rewardToken === null)
        {
            return
        }

        //additional reward
        let additionalRewardInput = <></>
        if (this.additionalRewardToken !== null)
        {
            additionalRewardInput = (
                <>
                    {this.renderSettingsCol("Min Additional Reward",
                        <InputTokenAmountConverted
                            ref={this.refInputMinAdditionalReward}
                            token={this.additionalRewardToken}
                            value={this.state.minAdditionalRewardToReward}
                            onChange={() => this.onChange_value()} />)}
                </>
            )
        }

        return (
            <Group>
                <Group className="row">
                    {this.renderSettingsRow("Auto Compound (deposit)",
                        <CheckBox
                            ref={this.refCheckBoxAutoCompound_deposit}
                            checked={this.state.autoCompoundBeforeDeposit}
                            onChange={() => this.onChange_value()} />,
                        "col-6")}
                    {this.renderSettingsRow("Auto Compound (withdraw)",
                        <CheckBox
                            ref={this.refCheckBoxAutoCompound_withdraw}
                            checked={this.state.autoCompoundBeforeWithdraw}
                            onChange={() => this.onChange_value()} />,
                        "col-6")}
                </Group>
                   
                {this.renderSettingsCol("Min Reward",
                    <InputTokenAmountConverted
                        ref={this.refInputMinReward}
                        token={this.rewardToken}
                        value={this.state.minRewardToDeposit}
                        onChange={() => this.onChange_value()} />)}
                {additionalRewardInput}
            </Group>
        )
    }

    renderButtonUpgradeStrategy()
    {
        if (this.vault === null
            || window.chef.toBN(this.vault.proposedStrategy).cmp(window.chef.toBN(0)) === 0)
        {
            return <></>;
        }

        //get end time
        const when = new Date(this.vault.proposedTime * 1000);
        when.setDate(when.getDate() + 1);
        let countdown = <></>
        const diff = (when.getTime() / 1000) - ((new Date()).getTime() / 1000)
        if (diff > 0)
        {
            countdown = <> in {LLib.getHMS(diff)}</>
        }

        return (
            <Button
                buttonStyle={diff > 0 ? 0 : 1}
                onClick={() => this.onClick_upgrade()}>
                upgrade {countdown}
            </Button>
        )
    }

	render()
	{
		let header = <Text size="1">Settings:</Text>
		let footer = (
            <>
                <Button buttonStyle="1" className="ModalButton" onClick={() => this.onClick_save()}>
                    save
                </Button>
                <Button buttonStyle="1" className="ModalButton" onClick={() => this.onClick_refresh()}>
                    refresh
                </Button>
                {this.renderButtonUpgradeStrategy()}
            </>
        )              

        let linkContract = <></>
        if (window.chef.currentChain !== null)
        {
            linkContract = (
                <Group className="settingsRow">
                    <Text color="2">
                        Strategy:
                    </Text>
                    <Link href={window.chef.currentChain.linkExplorerContract.replace("{address}", this.strategy?.address)} target="blank">
                        {LSymbols.link("svgLink contract", null, 'View Contract')}
                    </Link>
                </Group>
            )
        }

		return (
			<Modal
				show={this.state.show}
				className="ModalVaultStrategySettings sizeLarge"
                onClose={() => this.setShow(false)}
				header={header}
				footer={footer}>
                <Group className="settings">
                    {linkContract}                                          
                    {this.renderMinReward()}
                    {this.renderDust()}
                </Group>
			</Modal>
		)
	}
}

export default ModalVaultStrategySettings;