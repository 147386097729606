import React from 'react';

//libs
import {LWeb3} from '../../../../../libs/'

//components
import Modal from '../../../Modal'
import { Text, Button, Group } from '../../../../Controls'
import { InputTokenAmountMoonVault, ButtonBuySellToken, ProgressBarVaultBreakEven } from '../../../../Controls/AddOn'

//css
import './ModalVaultWithdraw.css'

class ModalVaultWithdraw extends React.Component
{
	constructor(props)
	{   
		super(props)
		
		//init state
		this.state = 
		{
            vault: props.vault || null,
            onClose: props.onClose || null
		}
	}

	close()
	{
		if (!!this.state.onClose)
        {
            this.state.onClose()
        }
	}
	
	async onClick_withdraw()
	{
		this.close()
		await this.state.vault.withdraw(this.getDialogTokensUint256())		
	}

	async onClick_withdrawAll()
	{
		this.close()
		await this.state.vault.withdrawAll()		
	}

	getDialogTokensUint256()
	{
		const i = document.getElementById("dialog_tokenInput")
		const v = LWeb3.tokensToUint256String(i.value);
		const val = window.chef.web3_data.eth.abi.encodeParameter("uint256", v);
		
		return val;
	}
    
	render()
	{
		let header = <Text size="1">Withdraw {this.state.vault.depositToken?.symbol}</Text>
		let footer = (
            <>
				<ButtonBuySellToken
					token={this.state.vault.depositToken}
					sell={true}
					showLabel={false} />
                <Button
                    className="ModalButton"
                    buttonStyle="1"
                    onClick={() => this.onClick_withdraw()}>
                    withdraw
                </Button>
				<Button
                    buttonStyle="1"
                    onClick={() => this.onClick_withdrawAll()}>
                    all
                </Button>
            </>
        )

		return (
			<Modal
				show={this.props.show}
				className="ModalVaultWithdraw sizeNormal"
				onClose={() => this.close()}
				header={header}
				footer={footer}>
				<Group className="content">
					<Text color="2">
                        Input how many {this.state.vault.depositToken?.symbol} you want to withdraw.
                    </Text>
                    <InputTokenAmountMoonVault
						balanceLabel="Deposit"
						token={this.state.vault.depositToken}
						max={this.state.vault.userDeposit}
						vault={this.state.vault}
						onChangeVault={() => this.state.vault.userDeposit} />					
					<ProgressBarVaultBreakEven
						vault={this.state.vault}
						withdraw={true}
						showLabels={true} />
				</Group>
			</Modal>
		)
	}
}

export default ModalVaultWithdraw;