
import React from 'react';
import {cloneDeep} from 'lodash';

//libs
import {LWeb3, LLib, LSymbols} from '../../../../libs/';

//components
import { Text, Group, Link, Toggle, Input } from '../../'
import { ChartForecast } from '../'

//css
import './ProfitCalculator.css'

const utilizeFocus = () => {
    const ref = React.createRef();
    const setFocus = () => {
		ref.current &&  ref.current.focus();
	};

    return {setFocus, ref};
}

class ProfitCalculator extends React.Component
{
	constructor(props)
	{   
		super(props)

		const tokenPrice = LWeb3.smartFormatFiat(this.props.vault.depositToken.unitPriceUSD, window.chef.stableToken, false) ?? 0;
		const apr = (this.props.vault.dailyAPR * 100 * 365).toFixed(2);
		const profitLossOrUserDeposit = this.props.vault.profitLoss ?? this.props.vault.userDeposit;
		let uvl = (tokenPrice * LWeb3.smartFormatFiat(profitLossOrUserDeposit, this.props.vault.depositToken, false)).toFixed(4) ?? 100;

		if (uvl === 0) {
			uvl = 100;
		}

		let uvlUpper = uvl < 10 ? 1000 : (uvl * 1000);
		let aprUpper = apr < 100 ? 200 : 999;

		if (aprUpper < 50)
		{
			aprUpper = 100;
		}

		aprUpper = aprUpper.toFixed();
		uvlUpper = uvlUpper.toFixed();

		const initial = {
			apr:  apr,
			tokenPrice: tokenPrice,
			uvl: uvl,
			split: 50,
			aprUpper: aprUpper,
			uvlUpper: uvlUpper
		};

		//init state
		this.state = 
		{
			initial,
			estimate: cloneDeep(initial),
			showUSD: true
		}

		this.vault = props.vault;
		this.version = props.version ?? 1;
		if (typeof this.version === "string") {
			this.version = parseInt(this.version);
		}

		this.resetEstimate = this.resetEstimate.bind(this);

		this.inputFocus = {
			Amount: utilizeFocus(),
			APR: utilizeFocus(),
			"BUSD Percent": utilizeFocus()
		};
	}

	componentDidMount()
	{
		if (this.props.reset) 
		{
			this.props.reset(this.resetEstimate);
		}
	}

	generateSeries(numOfCompounds, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit, isBusd) {
		if (typeof estimateApr === "string") {
			estimateApr = parseFloat(estimateApr);
		}
		if (typeof estimateTokenPrice === "string") {
			estimateTokenPrice = parseFloat(estimateTokenPrice);
		}		
		if (typeof estimateTokenUvl === "string") {
			estimateTokenUvl = parseFloat(estimateTokenUvl);
		}
		if (typeof estimateSplit === "string") {
			estimateSplit = parseInt(estimateSplit);
		}

		// const showUSD = this.state.showUSD;
		
		let counter = numOfCompounds;
		let amount = estimateTokenUvl;
		let busd = 0;
		let date = new Date().getTime();
		const series = [];

		// if (!showUSD) {
		// 	const tokenPrice = this.state.initial.tokenPrice;
		// 	amount = tokenPrice * LWeb3.smartFormatFiat(amount * (10 ** 18), this.props.vault.depositToken, false);
		// }
		
		while(counter >= 0) {
			let dailyInterest = (estimateApr / 365 / 100) * amount;
			let split = dailyInterest * (estimateSplit / 100);

			amount += dailyInterest - split;
			busd += split;

			if (isBusd)
				series.push([date, busd.toFixed(2)]);
			else
				series.push([date, amount.toFixed(2)]);

			date += 86400000;

			counter--;
		}

		return series;
	}

	calculateBusdEarnings(numOfCompounds, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit) {
		if (typeof estimateApr === "string") {
			estimateApr = parseFloat(estimateApr);
		}
		if (typeof estimateTokenPrice === "string") {
			estimateTokenPrice = parseInt(estimateTokenPrice);
		}		
		if (typeof estimateTokenUvl === "string") {
			estimateTokenUvl = parseInt(estimateTokenUvl);
		}
		if (typeof estimateSplit === "string") {
			estimateSplit = parseInt(estimateSplit);
		}

		let counter = numOfCompounds;
		let amount = estimateTokenUvl;
		let busd = 0;

		while(counter >= 0) {
			let dailyInterest = (estimateApr / 365 / 100) * amount;
			let split = dailyInterest * (estimateSplit / 100);

			amount += dailyInterest - split;
			busd += split;

			counter--;
		}

		return busd.toFixed(2);
	}

	calculateEarnings(numOfCompounds, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit) {
		if (typeof estimateApr === "string") {
			estimateApr = parseFloat(estimateApr);
		}
		if (typeof estimateTokenPrice === "string") {
			estimateTokenPrice = parseFloat(estimateTokenPrice);
		}		
		if (typeof estimateTokenUvl === "string") {
			estimateTokenUvl = parseFloat(estimateTokenUvl);
		}
		if (typeof estimateSplit === "string") {
			estimateSplit = parseInt(estimateSplit);
		}

		let counter = numOfCompounds;
		let amount = estimateTokenUvl;

		while(counter >= 0) {
			let dailyInterest = (estimateApr / 365 / 100) * amount;
			let split = dailyInterest * (estimateSplit / 100);

			amount += dailyInterest - split;

			counter--;
		}

		return amount.toFixed(2);
	}

	change_tokenPrice(tokenPrice) {
		let newEstimate = cloneDeep(this.state.estimate);
		newEstimate.tokenPrice = tokenPrice;

		this.setState({estimate: newEstimate});
	}

	change_apr(apr) {
		let newEstimate = cloneDeep(this.state.estimate);
		newEstimate.apr = apr;

		this.setState({estimate: newEstimate});
	}

	change_uvl(uvl) {
		// const showUSD = this.state.showUSD;

		let newEstimate = cloneDeep(this.state.estimate);

		// if (showUSD) {
			newEstimate.uvl = uvl;
		// } else {
		// 	const tokenPrice = this.state.initial.tokenPrice;
		// 	newEstimate.uvl = (tokenPrice * LWeb3.smartFormatFiat(uvl * (10 ** 18), this.props.vault.depositToken, false)).toFixed(4);
		// }

		this.setState({estimate: newEstimate});
	}

	change_split(split) {
		let newEstimate = cloneDeep(this.state.estimate);
		newEstimate.split = split;

		this.setState({estimate: newEstimate});
	}

	resetEstimate(_event) {
		this.setState({estimate: cloneDeep(this.state.initial)});

		_event?.stopPropagation();
		_event?.preventDefault();
		return false;
	}

	fiatFormatter(_value) {
		if (this.state.showUSD) 
		{
			return LLib.formatFiat(_value);
		}

		return LLib.smartFormatFloatDisplay(_value, true);
	}

	renderTable(estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit) {
		let daily = 0;
		let weekly = 0;
		let monthly = 0;
		let yearly = 0;

		let busdDaily = 0;
		let busdWeekly = 0;
		let busdMonthly = 0;
		let busdYearly = 0;

		if (this.version === 1) 
		{
			daily = this.calculateEarnings(1, estimateApr, estimateTokenPrice, estimateTokenUvl, 0);
			weekly = this.calculateEarnings(7, estimateApr, estimateTokenPrice, estimateTokenUvl, 0);
			monthly = this.calculateEarnings(30, estimateApr, estimateTokenPrice, estimateTokenUvl, 0);
			yearly = this.calculateEarnings(365, estimateApr, estimateTokenPrice, estimateTokenUvl, 0);
		}
		else
		{
			daily = this.calculateEarnings(1, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit);
			weekly = this.calculateEarnings(7, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit);
			monthly = this.calculateEarnings(30, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit);
			yearly = this.calculateEarnings(365, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit);

			busdDaily = this.calculateBusdEarnings(1, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit);
			busdWeekly = this.calculateBusdEarnings(7, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit);
			busdMonthly = this.calculateBusdEarnings(30, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit);
			busdYearly = this.calculateBusdEarnings(365, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit);
		}

		let profitDaily = ((daily - estimateTokenUvl) + parseFloat(busdDaily)).toFixed(2);
		let profitWeekly = ((weekly - estimateTokenUvl) + parseFloat(busdWeekly)).toFixed(2);
		let profitMonthly = ((monthly - estimateTokenUvl) + parseFloat(busdMonthly)).toFixed(2);
		let profitYearly = ((yearly - estimateTokenUvl) + parseFloat(busdYearly)).toFixed(2);
		
		const fiatFormatter = (v) => 
		{
			return this.fiatFormatter(v);
		};
		
		if (this.version === 1) {
			return (
				<Group>
					<Group className="row detail-row header">
						<Group className="col-4"></Group>
						<Group className="col-4">Compound</Group>
						<Group className="col-4">Profit</Group>
					</Group>
					<Group className="row detail-row">
						<Group className="col-4">1 Day</Group>
						<Group className="col-4">{fiatFormatter(daily)}</Group>
						<Group className="col-4">{fiatFormatter(profitDaily)}</Group>
					</Group>
					<Group className="row detail-row">
						<Group className="col-4">1 Week</Group>
						<Group className="col-4">{fiatFormatter(weekly)}</Group>
						<Group className="col-4">{fiatFormatter(profitWeekly)}</Group>
					</Group>
					<Group className="row detail-row">
						<Group className="col-4">1 Month</Group>
						<Group className="col-4">{fiatFormatter(monthly)}</Group>
						<Group className="col-4">{fiatFormatter(profitMonthly)}</Group>
					</Group>
					<Group className="row detail-row">
						<Group className="col-4">1 Year</Group>
						<Group className="col-4">{fiatFormatter(yearly)}</Group>
						<Group className="col-4">{fiatFormatter(profitYearly)}</Group>
					</Group>
				</Group>
			);
		} else {
			return (
				<Group>
					<Group className="row detail-row header">
						<Group className="col-3"></Group>
						<Group className="col-3">Compound</Group>
						<Group className="col-3">BUSD</Group>
						<Group className="col-3">Profit</Group>
					</Group>
					<Group className="row detail-row">
						<Group className="col-3">1 Day</Group>
						<Group className="col-3">{fiatFormatter(daily)}</Group>
						<Group className="col-3">{fiatFormatter(busdDaily)}</Group>
						<Group className="col-3">{fiatFormatter(profitDaily)}</Group>
					</Group>
					<Group className="row detail-row">
						<Group className="col-3">1 Week</Group>
						<Group className="col-3">{fiatFormatter(weekly)}</Group>
						<Group className="col-3">{fiatFormatter(busdWeekly)}</Group>
						<Group className="col-3">{fiatFormatter(profitWeekly)}</Group>
					</Group>
					<Group className="row detail-row">
						<Group className="col-3">1 Month</Group>
						<Group className="col-3">{fiatFormatter(monthly)}</Group>
						<Group className="col-3">{fiatFormatter(busdMonthly)}</Group>
						<Group className="col-3">{fiatFormatter(profitMonthly)}</Group>
					</Group>
					<Group className="row detail-row">
						<Group className="col-3">1 Year</Group>
						<Group className="col-3">{fiatFormatter(yearly)}</Group>
						<Group className="col-3">{fiatFormatter(busdYearly)}</Group>
						<Group className="col-3">{fiatFormatter(profitYearly)}</Group>
					</Group>
				</Group>
			);
		}
	}

	sliderInputBlur() {
		this.setState(this.recalibrateSliderRange({editingSlider: null}));
	}

	recalibrateSliderRange(_estimate) {
		let newEstimate = _estimate?.estimate ?? cloneDeep(this.state.estimate);

		newEstimate.uvlUpper = newEstimate.uvl < 10 ? 1000 : (newEstimate.uvl * 2);

		const newState = _estimate ?? {};
		newState.estimate = newEstimate;

		if (_estimate)
		{
			return newState;
		}
		
		this.setState(newState);
	}

	renderSlider(label, min, max, value, handler, visible, valueFormatter) {
		if (visible === false) {
			return <></>;
		}

		const id = label.replace(' ', '_');
		const editing = this.state.editingSlider === label;

		let formattedValue = value;
		if (typeof valueFormatter === 'function') {
			formattedValue = valueFormatter(formattedValue);
		}

		const blur = this.sliderInputBlur.bind(this);

		return (
			<Group>
				<Group style={{display: (editing ? "initial" : "none")}}>
					<Group className="row detail-row">
						<Group className="col-3"
							style={{marginTop:"7px", display: "inline-block"}}
							>
							<label htmlFor={id}>{label}</label>
						</Group>
						<Group className="col-9" style={{margin: "5px 0"}}>
							<Input type="text"
								ref={this.inputFocus[label].ref}
								id={id + "txt2"} name={id + "txt2"}
								title={formattedValue}
								value={value}
								onBlur={(e) => blur(e)}
								onChange={e => handler(e.target.value)}
							></Input>
						</Group>
					</Group>
				</Group>
				<Group style={{display: (!editing ? "initial" : "none")}}>
					<Group className="row detail-row">
						<Group className="col-3 d-none d-md-block">
							<label htmlFor={id}
								style={{marginTop:"7px", display: "inline-block"}}
								>{label}</label>
						</Group>
						<Group className="col-6 d-none d-md-block">
							<Input type="range" min={min} max={max} value={value} step="1"
								id={id} name={id}
								title={formattedValue}
								onChange={e => handler(e.target.value)}
							></Input>
						</Group>
						<Group className="col-3 d-none d-md-block clickable"
							style={{paddingTop:"7px"}}
							onClick={() => {
									this.setState({editingSlider: label});
									window.setTimeout(() => this.inputFocus[label].setFocus(), 0);
								}}>
							{formattedValue}
						</Group>
						<Group className="col-3 d-md-none">
							<label htmlFor={id + "txt"}
								style={{marginTop:"7px", display: "inline-block"}}
								>{label}</label>
						</Group>
						<Group className="col-9 d-md-none" style={{margin: "5px 0"}}>
							<Input
								type="number"
								min="0"
								inputmode="numeric" pattern="[0-9]*"
								id={id + "txt"} name={id + "txt"}
								title={formattedValue}
								value={value}
								onChange={e => handler(e.target.value)}
							></Input>
						</Group>
					</Group>
				</Group>
			</Group>
		);
	}

	changeUsdTokenView() {
		const currentShowUSD = this.state.showUSD;
		const tokenPrice = this.state.initial.tokenPrice;

		let newEstimate = cloneDeep(this.state.estimate);

		// Convert UVL to new format
		if (currentShowUSD) 
		{
			// Swapping to token view
			newEstimate.uvl = newEstimate.uvl / tokenPrice;
		}
		else 
		{
			// Swapping to token view
			newEstimate.uvl = newEstimate.uvl * tokenPrice;
		}

		this.setState(this.recalibrateSliderRange({showUSD: !currentShowUSD, estimate: newEstimate}));
	}

	render()
	{
		const vault = this.vault;

		if (!vault || !vault.depositToken || !vault.depositToken.symbol)
			return <></>;

		const showUSD = this.state.showUSD;

		let estimateApr = this.state.estimate.apr;
		let estimateTokenPrice = this.state.estimate.tokenPrice;
		let estimateTokenUvl = this.state.estimate.uvl;
		let estimateSplit = this.state.estimate.split;

		let series = [];

		if (this.version === 1) {
			series.push(
				{
					name: "Compounding",
					data: this.generateSeries(365, estimateApr, estimateTokenPrice, estimateTokenUvl, 0, false)
				});
		} else {
			series.push(
				{
					name: "Compounding",
					data: this.generateSeries(365, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit, false)
				});

			series.push(
				{
					name: "BUSD",
					data: this.generateSeries(365, estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit, true)
				});
		}

		let uvlUpper = this.state.estimate.uvlUpper;
		let aprUpper = this.state.estimate.aprUpper;
		
		const fiatFormatter = (v) => 
		{
			return this.fiatFormatter(v);
		};
		const percentFormatter = (v) => LLib.smartFormatPercent(v);

		let reset = <></>;
		if (typeof this.props.showReset !== "boolean" || this.props.showReset) 
		{
			reset = (
				<div className="row">
					<Link href="#" target="blank" title="Reset Calculator">
						{LSymbols.refresh("svgLink",  (e) => this.resetEstimate(e))}
					</Link>
				</div>
			);
		}

		return (
			<Group className="row details">
				<Group className="row">
					<Group className="col-12">
						<Group>
							<Text size="1" className="heading">Earnings</Text>
						</Group>
						{this.renderTable(estimateApr, estimateTokenPrice, estimateTokenUvl, estimateSplit)}
					</Group>
					<Group className="col-12">
						<ChartForecast key={showUSD} series={series} showUSD={showUSD} tokenPrice={this.state.initial.tokenPrice}></ChartForecast>
					</Group>
				</Group>

				<Text style={{marginTop: "20px"}} className="underlined">
					<div className="row">
						<div className="col-7">
							Estimation Calculator
						</div>
						<div className="col-5">
							<Toggle labelOn="USD" labelOff="Tokens" checked={showUSD} onChange={checked => this.changeUsdTokenView()}></Toggle>
						</div>
					</div>
				</Text>

				<div className="row">
					{this.renderSlider('Amount', 0, uvlUpper, estimateTokenUvl, this.change_uvl.bind(this), null, fiatFormatter)}
					{this.renderSlider('APR', 0, aprUpper, estimateApr, this.change_apr.bind(this), null, percentFormatter)}
					{this.renderSlider('BUSD Percent', 0, 100, estimateSplit, this.change_split.bind(this), this.version >= 2, percentFormatter)}
				</div>

				{reset}
			</Group>
		);
	}
}

export default ProfitCalculator;