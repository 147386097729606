import React from 'react';

//components
import { Text, Group, Panel } from '../../Controls'

//css
import './PageOnRamp.css'

class PageOnRamp extends React.Component
{
	constructor(props)
	{   
		super(props)
		 
		//init state
		this.state = 
		{
			updateRevision: 0
		}
	}

    render()
    {
        return (
			<Group className="PageHome">
				<Panel className="Panel_Info">
					<Group className="header">
						<Text size="2">
							Fiat onRamp
						</Text>
					</Group>			
					<iframe
                        id="onramper_widget"
                        src={
                            "https://widget.onramper.com"
                            + "?apiKey=pk_test_x5M_5fdXzn1fxK04seu0JgFjGsu7CH8lOvS9xZWzuSM0"
                            + "&color=ffae00"
                            + "&darkMode=true"
                            + "&onlyCryptos=BNB,BUSD,MATIC"
                        }                            
                        height="595px"
                        width="440px"
                        title="Onramper widget"
                        frameborder="0"
                        allow="accelerometer; autoplay; camera; gyroscope; payment" />
				</Panel>
			</Group>
		)
    }
}

export default PageOnRamp;