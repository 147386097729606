import React from 'react';

//libs
import { LLib } from '../../../../libs'

//components
import Modal from '../../Modal'
import { Group, Text, Image, Button, Link } from '../../../Controls'

//css
import './ModalWelcome.css'

//vars
var modal_welcome = null

class ModalWelcome extends React.Component
{
    constructor(props)
	{   
		super(props)
		
		//init state
		this.state = 
		{
            show: props.show || false	
		}

		this.resolvePromise = null;

		//vars
		modal_welcome = this;		
	}

	static async showModal()
	{
		return new Promise((_resolve, _reject) =>
		{			
			if (LLib.getStorage_bool(false, 'welcome', false))
			{
				_resolve();								
			}
			else
			{
				modal_welcome.resolvePromise = _resolve;
				modal_welcome.setShow(true)
			}
		});
	}

	setShow(_show)
	{
		this.setState(
		{
			show: _show
		})
	}

	async onClick_close()
	{
        LLib.setStorage(false, 'welcome', true);		
        this.setShow(false)
		this.resolvePromise();
	}

	render()
	{
		let header = <Text size="1">Welcome</Text>
		let footer = (
            <>
                <Button className="ModalButton" onClick={() => this.onClick_close()}>
                    OK
                </Button>
            </>
        )

		return (
			<Modal
				show={this.state.show}
				className="ModalWelcome sizeNormal"
				onClose={() => this.onClick_close()}
				header={header}
				footer={footer}>
				<Text color="2">
                    <Group className="WelcomeMessage">
                        <Group className="Audits">
                            <Text>
                                Audited by:
                            </Text>
							<Link href="https://github.com/MaverickHP/brewlabs-audits/blob/main/Brewlabs%20-%20MoonVault%20Block%2012687300.pdf" target="blank">
								<Image src="./assets/partners/Brewlabs.png" />
							</Link>
                        </Group>
                        <Group className="KYC">
                            <Text>
                                KYC by:
                            </Text>
							<Link href="https://rugdoc.io/project/moon-vault/" target="blank">
                            	<Image src="./assets/partners/RugDoc.png" />
							</Link>
                        </Group>
                    </Group>              
				</Text>
			</Modal>
		)
	}
}

export default ModalWelcome;