//libs
import {LLib} from '../libs/LLib'
import {LWeb3} from '../libs/LWeb3'

//classes
import Cache from './Cache'
import VaultStrategy from './VaultStrategy'

//contracts
import {ABI_Vault} from '../contracts/Vault'

class Vault
{
	////////////////////////////////////

	constructor(_data, _platform)
	{
		//init
		this.initialized = false
		this.initializedInfo = false
		this.initializedUser = false
		this.initializedDB = false
		
		//base values
		this.id = _data.id
		this.address = _data.contract
		this.name = _data.name
		this.platform = _platform
		this.originBlock = _data.originBlock || 0
		this.icon = _data.icon || ""
		this.depositTokenAddress = _data.depositToken
		this.rewardTokenAddress = _data.rewardToken
		this.strategyAddress = ""
		this.strategy = null
		this.additionalDepositTax = (_data.additionalDepositTax || 0) / 100
		this.additionalWithdrawTax = (_data.additionalWithdrawTax || 0) / 100
		
		//values
		this.versionString = "0.0"
		this.version = LLib.getVersion(this.versionString)
		this.router = ""
		
		//fees
		this.depositFee = 0
		this.withdrawFee = 0
		this.totalDepositFeeTaxFactor = 1
        this.totalWithdrawFeeTaxFactor = 1
		this.calculateFees()
				
		//get total values
		this.totalDeposit = "0"
		this.totalPending = "0"
		
		//get user value		
		this.userDeposit = "0"
		this.userPending = "0"
		this.userProfitLoss = 0

		//apr
		this.apr = 0
		this.dailyAPR = 0
		this.dailyLPFeeAPR = 0
		this.lpFeeAPR = 0
		this.compoundedDailyAPR = 0
		this.compounds24h = 1
		this.compoundedAPY = 0
		this.combinedAPY = 0
		this.combinedDailyAPR = 0

		//meta data
		this.lastCompound = null
		this.isFarmable = false
		this.allocPoints = 1
		this.startBlock = 0
		this.endBlock = 0
		this.endTime = null
		this.harvestLockUntil = null
		this.isApproved = false
		this.proposedStrategy = "0"
		this.proposedTime = 0

		//usd
		this.totalDepositUSD = "0"
		this.totalPendingUSD = "0"
		this.userDepositUSD = "0"
		this.userPendingUSD = "0"
		this.compoundRewardUSD = "0"

		//stats	
		this.roiDailyAPR = 0
		this.roiWeeklyAPR = 0
		this.roiMonthlyAPR = 0
		this.roiYearlyAPR = 0
		this.roiDailyAPY = 0
		this.roiWeeklyAPY = 0
		this.roiMonthlyAPY = 0
		this.roiYearlyAPY = 0

		//cache
        this.cache_vaultCompoundHistory = new Cache(async (_p) => await this.db_getHistoricCompounds(_p, true), 60)

		//db
		this.dailyAPR = 0
		this.db =
		{
			syncBlock: 0,
			processBlock: 0,
			lastCompound: 0,
			lastCompoundUTC: new Date(0)
		}
		this.last =
		{
			syncBlock: 0,
			processBlock: 0
		}
		this.lastUserTransaction = null

		//extended data		
		this.depositToken = window.chef.findToken(this.depositTokenAddress)
		this.rewardToken = window.chef.findToken(this.rewardTokenAddress)
		window.chef.addDepositToken(this.depositToken)
	}

	////////////////////////////////////
	
	debugErrorString(_text)
	{
		return 'Vault [' + this.id + '] failed at: ' + _text		
	}

	getContract(_user)
    {       
        let web3 = window.chef.selectWeb3Connection(_user)
        let con = new web3.eth.Contract(ABI_Vault, this.address)
        return con
    }

	async getStrategy()
	{
		if (this.strategy === null)
		{
			await this.reloadVaultInfo()
		}

		return this.strategy
	}

	////////////////////////////////////

    static async batchInit(_vaults)
    {
        let batchObjects = []
        let batchCalls = []

        //get valid vaults
        _vaults.forEach((v) => 
        {
            if (!v.initialized
                && v.address !== "")
            {     
                batchObjects.push(v)
                batchCalls.push(v.makeRequest_init())              
            }
        })
        if (batchObjects.length !== 0)
        {
			//make multicall
			const mc = window.chef.makeMultiCall(false)
			const [ret] = await LWeb3.tryMultiCall(mc, batchCalls, "[Vault] batch init", "Vault: init")

			//process calls
			for (let n = 0; n < batchObjects.length; n++)
			{
				const t = batchObjects[n]
				const r = ret[n]
				await t.processRequest_init(r)
			}
		}

        return _vaults.filter(v => v.initialized)
    }

    makeRequest_init()
    {
        const con = this.getContract()
        return {
			versionString: con.methods.VERSION(),
			depositToken: con.methods.depositToken(),
			rewardToken: con.methods.rewardToken(),
			depositFee: con.methods.poolDepositFee(),
			withdrawFee: con.methods.poolWithdrawFee(),				
			allocPoints: con.methods.poolAllocPoints(),
			startBlock: con.methods.poolStartBlock(),  
			endBlock: con.methods.poolEndBlock(),
			endTime: con.methods.poolEndTime(),
			isFarmable: con.methods.isPoolFarmable(),
			lastCompound: con.methods.lastCompound(),
			harvestLockUntil: con.methods.poolHarvestLockUntil(),
			strategyAddress: con.methods.strategy(),
		}
    }

    async processRequest_init(_data)
    {
        this.versionString 			= _data.versionString
		this.depositFee				= parseFloat(_data.depositFee) / window.vaultChef.percentFactor
		this.withdrawFee			= parseFloat(_data.withdrawFee) / window.vaultChef.percentFactor
		this.allocPoints			= parseInt(_data.allocPoints)
		this.startBlock				= parseInt(_data.startBlock)
		this.endBlock				= parseInt(_data.endBlock)
		this.endTime				= (parseInt(_data.endTime) === 0 ? null : new Date(parseInt(_data.endTime) * 1000))
		this.isFarmable				= _data.isFarmable
		this.lastCompound			= (parseInt(_data.lastCompound) === 0 ? null : new Date(parseInt(_data.lastCompound) * 1000))
		this.harvestLockUntil		= (parseInt(_data.harvestLockUntil) === 0 ? null : new Date(parseInt(_data.harvestLockUntil) * 1000))
		this.strategyAddress		= _data.strategyAddress;		

		//process
		this.version = LLib.getVersion(this.versionString)
		this.strategy = new VaultStrategy(this.strategyAddress)
		this.calculateFees()

		//complete
		this.initialized = true
    }    

	async init()
	{
		if (this.initialized)
		{
			return
		}

		//handle result
        const [ret] = await LWeb3.tryMultiCall(
			window.chef.makeMultiCall(false),
			[
				this.makeRequest_init()
			],
			this.debugErrorString("init"),
			"Vault: init")
        this.processRequest_init(ret[0])        
	}

	////////////////////////////////////

	static async batchLoadVaultInfo(_vaults)
    {
        let batchObjects = []
        let batchCalls = []

        //get valid vaults
        const initVaults = await Vault.batchInit(_vaults)
        initVaults.forEach((v) => 
        {            
			batchObjects.push(v)
			batchCalls.push(v.makeRequest_vaultInfo())
        })
        if (batchObjects.length === 0)
        {
            return
        }

        //make multicall
        const mc = window.chef.makeMultiCall(false)
        const [ret] = await LWeb3.tryMultiCall(mc, batchCalls, "[Vault] batch vaultInfo", "Vault: vaultInfo")

        //process calls
        for (let n = 0; n < batchObjects.length; n++)
        {
            const t = batchObjects[n]
            const r = ret[n]
            await t.processRequest_vaultInfo(r)
        }
    }

    makeRequest_vaultInfo()
    {
        const con = this.getContract()
        return {
			totalDeposit: con.methods.balance(),
			totalPending: con.methods.poolPending(),				
			isFarmable: con.methods.isPoolFarmable(),
			lastCompound: con.methods.lastCompound(),
			harvestLockUntil: con.methods.poolHarvestLockUntil(),
			strategyCandidate: con.methods.strategyCandidate()
		}
    }

    async processRequest_vaultInfo(_data)
    {
        this.totalDeposit 			= _data.totalDeposit
        this.totalPending 			= _data.totalPending        
		this.isFarmable				= _data.isFarmable
		this.lastCompound			= (_data.lastCompound === 0 ? null : new Date(parseInt(_data.lastCompound) * 1000))
		this.harvestLockUntil		= (_data.lastCompound === 0 ? null : new Date(parseInt(_data.harvestLockUntil) * 1000))
		this.proposedStrategy		= _data.strategyCandidate[0]
		this.proposedTime			= parseInt(_data.strategyCandidate[1])

		//process
		this.totalDepositUSD = this.depositToken.getPriceUSDForAmount(this.totalDeposit)
		this.totalPendingUSD = this.depositToken.getPriceShare(this.rewardToken.getPriceUSDForAmount(this.totalPending), 1 - window.chef.vaultChef.totalProfitFee)
		this.compoundRewardUSD = this.depositToken.getPriceShare(this.rewardToken.getPriceUSDForAmount(this.totalPending), window.chef.vaultChef.compoundRewardFee)

		//complete
		this.initializedInfo = true

		//event
        document.dispatchEvent(new CustomEvent('vault_vaultInfo',
        {
            detail:
            {
                address: this.address
            }
        }))
    }

	async reloadVaultInfo()
	{
		//lazy init
		await this.init()
		if (!this.initialized)
		{
			return
		}

		//handle result
        const [ret] = await LWeb3.tryMultiCall(
			window.chef.makeMultiCall(false),
			[
				this.makeRequest_vaultInfo()
			],
			this.debugErrorString("vaultInfo"),
			"Vault: vaultInfo")
		this.processRequest_vaultInfo(ret[0])        
	}

	////////////////////////////////////

	static async batchLoadUserInfo(_vaults)
    {
        let batchObjects = []
        let batchCalls = []

        //get valid vaults
        const initVaults = await Vault.batchInit(_vaults)
        initVaults.forEach((v) => 
        {
            batchObjects.push(v)
            batchCalls.push(v.makeRequest_userInfo())
        })
        if (batchObjects.length === 0)
        {
            return
        }

        //make multicall
        const mc = window.chef.makeMultiCall(true)
        const [ret] = await LWeb3.tryMultiCall(mc, batchCalls, "[Vault] batch userInfo", "Vault: userInfo")

        //process calls
        for (let n = 0; n < batchObjects.length; n++)
        {
            const t = batchObjects[n]
            const r = ret[n]
            await t.processRequest_userInfo(r)
        }
    }

    makeRequest_userInfo()
    {
        const con = this.getContract(true)
        return {
			userDeposit: con.methods.balanceOf(window.chef.account),
			userPending: con.methods.userPending(window.chef.account),
			isApproved: con.methods.checkApproved(window.chef.account)		
		}
    }

    async processRequest_userInfo(_data)
    {
        this.userDeposit 	= _data.userDeposit
		this.userPending 	= _data.userPending
		this.isApproved		= _data.isApproved

		//process
		this.userDepositUSD = this.depositToken.getPriceUSDForAmount(this.userDeposit)
		this.userPendingUSD = this.depositToken.getPriceShare(this.rewardToken.getPriceUSDForAmount(this.userPending), 1 - window.chef.vaultChef.totalProfitFee)
		this.userProfitLoss = await this.calcUserProfitLoss()
		this.calculateEarnings()

		//complete
		this.initializedUser = true

		//event
        document.dispatchEvent(new CustomEvent('vault_userInfo',
        {
            detail:
            {
                address: this.address
            }
        }))
    }

	async reloadUserInfo()
	{
		//lazy init
		await this.init()
		if (!this.initialized)
		{
			return
		}

		//handle result
        const [ret] = await LWeb3.tryMultiCall(
			window.chef.makeMultiCall(true),
			[
				this.makeRequest_userInfo()
			],
			this.debugErrorString("userInfo"),
			"Vault: userInfo")
        this.processRequest_userInfo(ret[0])        
	}

	////////////////////////////////////

	static async batchReloadDBInfo()
	{
		let data = []
		try
		{
			let apiURL = window.chef.api_url + "?module=vault&action=getVaultsInfo"
			apiURL += "&chain=" + window.chef.currentChain.id
			apiURL += "&wallet=" + (window.chef.account || "")
			data = await LLib.fetchJSON(apiURL)
		}
		catch (e) { }

		for (let n = 0; n < data.length; n++)
		{
			const d = data[n]
			const v = window.chef.findVault(d.id)
			if (v === null)
			{
				continue
			}

			//process
			if (d.dateTime !== null)
			{
				v.compounds24h = d.compounds24h || 1					
				v.dailyAPR = parseFloat(d.dailyAPR) / 100
				v.dailyLPFeeAPR = parseFloat(d.dailyLPFeeAPR) / 100

				v.calculateAPR()
				v.calculateEarnings()
			}
			v.lastUserTransaction = d

			//complete
			v.initializedDB = true

			//event
			document.dispatchEvent(new CustomEvent('vault_userInfo',
			{
				detail:
				{
					address: v.address
				}
			}))
		}
	}

	async reloadDBInfo()
	{
		//daily APR
		await this.db_getDailyAPR()

		//account
		if (window.chef.account !== null)
		{
			//profit loss
			this.userProfitLoss = await this.calcUserProfitLoss()
		}

		//complete
		this.initializedDB = true

		//event
        document.dispatchEvent(new CustomEvent('vault_userInfo',
        {
            detail:
            {
                address: this.address
            }
        }))
	}

	////////////////////////////////////	

	async upgradeStrat()
	{
		const con = this.getContract(true)
		await window.chef.trySend(con.methods.upgradeStrat(), window.chef.account, this.debugErrorString("upgradeStrat"), undefined, `Upgrade Strategy ${this.id}`)
	}

	async checkApproved()
	{
		return await window.chef.vaultChef.checkVaultApproved(this.id)
	}

	async approve()
	{
		await this.depositToken.approve(this.address)
	}

	async compound()
	{
		await window.chef.vaultChef.compound(this.id, `Compound Vault ${this.depositToken.getFullName()}`)
	}

	async deposit(_amount)
	{
		const amountStr = LWeb3.smartFormatTokens(window.chef.toBN(_amount), this.depositToken, true)
		await window.chef.vaultChef.deposit(this.id, _amount, `Deposit ${amountStr} ${this.depositToken.getFullName()}`)
	}

	async withdraw(_amount)
	{
		const amountStr = LWeb3.smartFormatTokens(window.chef.toBN(_amount), this.depositToken, true)
		await window.chef.vaultChef.withdraw(this.id, _amount, `Withdraw ${amountStr} ${this.depositToken.getFullName()}`)
	}

	async withdrawAll()
	{
		await window.chef.vaultChef.withdrawAll(this.id, `Withdraw all ${this.depositToken.getFullName()}`)
	}

	////////////////////////////////////

	isFarm()
	{
		return this.depositToken.isLPToken()
	}

	hasStable()
	{
		return (this.depositToken.isStable()
			|| this.depositToken.hasStable())
	}

	userHasBalance()
	{
		return (this.depositToken.userBalance !== "0")
	}

	userHasDeposit()
	{
		return (this.userDeposit !== "0")
	}

	hasDepositAsset(_tokenAddress)
	{
		if (this.depositToken.isLPToken())
		{
			return LWeb3.checkEqualAddress(this.depositToken.token0, _tokenAddress)
				|| LWeb3.checkEqualAddress(this.depositToken.token1, _tokenAddress);
		}
		else
		{
			return LWeb3.checkEqualAddress(this.depositToken.address, _tokenAddress);
		}
	}

	////////////////////////////////////
	
	calculateFees()
	{
		this.totalDepositFeeTaxFactor = (1 - this.additionalDepositTax) * (1 - this.depositFee)
        this.totalWithdrawFeeTaxFactor = 1 / (1 - this.additionalWithdrawTax) / (1 - this.withdrawFee) / (1 - window.chef.vaultChef.withdrawFee)
	}

	calculateAPR()
	{
		if (this.compounds24h === 1)
		{
			this.compoundedDailyAPR = this.dailyAPR
		}
		else
		{
			this.compoundedDailyAPR = (Math.pow(((this.dailyAPR / this.compounds24h) + 1), this.compounds24h) - 1)
		}
		this.apr = this.compoundedDailyAPR * 365
		this.compoundedAPY = (Math.pow((this.dailyAPR / this.compounds24h) + 1, 365 * this.compounds24h) - 1)
		this.lpFeeAPR = this.dailyLPFeeAPR * 365
		this.combinedAPY = this.compoundedAPY + this.lpFeeAPR
		this.combinedDailyAPR = this.dailyAPR + this.dailyLPFeeAPR
	}

	calculateEarnings()
	{
		const depositUSD = LWeb3.smartFormatFiat(this.userDepositUSD, window.chef.stableToken)
		const dailyUSD = depositUSD * this.dailyAPR

		//apr	
		this.roiDailyAPR = dailyUSD
		this.roiWeeklyAPR = dailyUSD * 7
		this.roiMonthlyAPR = dailyUSD * 30
		this.roiYearlyAPR = dailyUSD * 365

		//apy
		this.roiDailyAPY = dailyUSD
		this.roiWeeklyAPY = depositUSD * (Math.pow(1 + this.dailyAPR, 7) - 1)
		this.roiMonthlyAPY = depositUSD * (Math.pow(1 + this.dailyAPR, 30) - 1)
		this.roiYearlyAPY = depositUSD * (Math.pow(1 + this.dailyAPR, 365) - 1)
	}

	async calcUserProfitLoss()
	{
		const lastTransaction = this.lastUserTransaction
		if (lastTransaction === null)
		{
			return 0
		}
		
		const profitLoss = this.calculateProfitLoss(
			window.chef.toBN(this.userDeposit),
			window.chef.toBN("0x" + lastTransaction.userDepositSum),
			window.chef.toBN("0x" + lastTransaction.userWithdrawSum),
			window.chef.toBN("0x" + lastTransaction.userReinvestedSum))
		return profitLoss
	}
	
	calculateProfitLoss(_currentDeposit, _depositSum, _withdrawSum, _reinvestedSum)
	{
		if (_depositSum.cmp(window.chef.toBN(0)) === 0)
		{
			return 0
		}
		
		const pf = window.chef.toBN(window.vaultChef.percentFactor)
		const pla = this.calcProfitLossAmount(_currentDeposit, _depositSum, _withdrawSum)
		const ri = this.calcRealInvestment(_depositSum, _reinvestedSum)		
		const profitLoss = pla.mul(pf).div(ri)		
		const percent = (parseFloat(parseInt(profitLoss.toString(10))) / window.vaultChef.percentFactor)
		
		return percent
	}
	
	calcProfitLossAmount(_currentDeposit, _depositSum, _withdrawSum)
	{
		return _currentDeposit.add(_withdrawSum).sub(_depositSum)		
	}
	
	calcRealInvestment(_depositSum, _reinvestedSum)
	{
		return _depositSum.sub(_reinvestedSum)
	}
	
	////////////////////////////////////

	async db_getHistoricCompounds(_filter, _forceReload)
	{
		_filter = _filter || {}

		if (_forceReload)
		{
			let data = undefined
			try
			{
				let apiURL = window.chef.api_url + "?module=vaultCompound&action=getVaultCompoundsPast"
				apiURL += "&chain=" + window.chef.currentChain.id
				apiURL += "&vault=" + this.id
				apiURL += "&days=" + (_filter.days || 30)
				data = await LLib.fetchJSON(apiURL)
			}
			catch (e) { }

			return data
		}

		return await this.cache_vaultCompoundHistory.getData(_filter)
	}

	async db_getDailyAPR()
	{
		//get daily apr
		try
		{
			let apiURL = window.chef.api_url + "?module=vault&action=getVaultAPR"
			apiURL += "&chain=" + window.chef.currentChain.id
			apiURL += "&vault=" + this.id
			let apiJson = await LLib.fetchJSON(apiURL)
			if (apiJson.block !== 0)
			{
				this.compounds24h = apiJson.compounds24h || 1					
				this.dailyAPR = parseFloat(apiJson.dailyAPR) / 100					
			}
		}
		catch (e) { }
		
		this.calculateAPR()
		this.calculateEarnings()
	}

	////////////////////////////////////
}

export default Vault;