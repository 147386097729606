//libs
import {LWeb3} from '../libs/LWeb3'

//contracts
import {ABI_Launchpad} from '../contracts/Launchpad'

class Launchpad
{
	////////////////////////////////////

	constructor(_address)
	{
		//init
		this.initialized = false
		this.initializedInfo = false
		this.initializedUser = false
		
		//config
        this.address = _address;
        this.depositTokenAddress = "";
        this.rewardTokenAddress = "";
        this.startTime = 0;
        this.duration = 0;
        this.withdrawDeadlineDuration = 0;
        this.softCap = "0";
        this.hardCap = "0";
        this.price = "0";
        this.missingRewards = "0";
        this.owner = "";
        this.approved = false;
        this.approvedReward = false;
    
        //runtime
        this.totalDeposit = "0";
        this.totalClaimed = "0";

        //user
        this.userDeposit = "0";
        this.userBalance = "0";
        this.userClaimed = "0";
        this.userClaimedSoft = false;
        this.userClaimedHard = false;
        this.userClaimableSoft = "0";
        this.userClaimableHard = "0";
        this.userWithdrawableSoft = "0";
        this.userWithdrawableHard = "0";

		//extended data		
		this.depositToken = null;
		this.rewardToken = null;
	}

	////////////////////////////////////
	
	debugErrorString(_text)
	{
		return 'Launchpad failed at: ' + _text		
	}

	getContract(_user)
    {       
        let web3 = window.chef.selectWeb3Connection(_user)
        let con = new web3.eth.Contract(ABI_Launchpad, this.address)
        return con
    }

	////////////////////////////////////

    makeRequest_init()
    {
        const con = this.getContract()
        return {
			depositToken: con.methods.depositToken(),
			rewardToken: con.methods.rewardToken(),
			startTime: con.methods.startTime(),
			duration: con.methods.duration(),				
			withdrawDeadlineDuration: con.methods.withdrawDeadlineDuration(),
			softCap: con.methods.softCap(),  
			hardCap: con.methods.hardCap(),
			price: con.methods.price(),
            owner: con.methods.owner()
		}
    }

    async processRequest_init(_data)
    {
        this.depositTokenAddress        = _data.depositToken;
        this.rewardTokenAddress         = _data.rewardToken;
		this.startTime				    = (parseInt(_data.startTime) === 0 ? null : new Date(parseInt(_data.startTime) * 1000))
		this.duration			        = parseInt(_data.duration);
		this.withdrawDeadlineDuration	= parseInt(_data.withdrawDeadlineDuration);
		this.softCap				    = _data.softCap;
		this.hardCap				    = _data.hardCap;
        this.price                      = _data.price;		
        this.owner                      = _data.owner;

		//process
		this.depositToken = window.chef.findToken(this.depositTokenAddress)
		this.rewardToken = window.chef.findToken(this.rewardTokenAddress)
		window.chef.addDepositToken(this.depositToken)

		//complete
		this.initialized = true
    }    

	async init()
	{
		if (this.initialized)
		{
			return
		}

		//handle result
        const [ret] = await LWeb3.tryMultiCall(
			window.chef.makeMultiCall(false),
			[
				this.makeRequest_init()
			],
			this.debugErrorString("init"),
			"Launchpad: init")
        this.processRequest_init(ret[0])        
	}

	////////////////////////////////////

    makeRequest_Info()
    {
        const con = this.getContract()
        return {
			totalDeposit: con.methods.totalDeposit(),
			totalClaimed: con.methods.totalClaimed(),
            missingRewards: con.methods.getMissingRewards(),
            duration: con.methods.duration()
		}
    }

    async processRequest_Info(_data)
    {
        this.totalDeposit 			= _data.totalDeposit
        this.totalClaimed 			= _data.totalClaimed    
        this.missingRewards         = _data.missingRewards
        this.duration			    = parseInt(_data.duration);

		//complete
		this.initializedInfo = true

        //process
        if (!this.approved)
        {
            this.approved = await this.depositToken.checkApproved(this.address);
        }
        if (!this.approvedReward
            && LWeb3.compareAddress(this.owner, window.chef.account) === 0)
        {
            this.approvedReward = await this.rewardToken.checkApproved(this.address);            
        }

		//event
        document.dispatchEvent(new CustomEvent('launchpad_Info',
        {
            detail:
            {
                address: this.address
            }
        }))
    }

	async reloadInfo()
	{
		//lazy init
		await this.init()
		if (!this.initialized)
		{
			return
		}

		//handle result
        const [ret] = await LWeb3.tryMultiCall(
			window.chef.makeMultiCall(false),
			[
				this.makeRequest_Info()
			],
			this.debugErrorString("Info"),
			"Launchpad: Info")
		this.processRequest_Info(ret[0])        
	}

	////////////////////////////////////

    makeRequest_userInfo()
    {
        const con = this.getContract(true)
        return {
			userInfo: con.methods.getUserInfo(window.chef.account),
            userClaimableSoft : con.methods.getUserRewardShare(window.chef.account, false),
            userClaimableHard : con.methods.getUserRewardShare(window.chef.account, true),
            userWithdrawableSoft : con.methods.getUserOverSubscribed(window.chef.account, false),
            userWithdrawableHard : con.methods.getUserOverSubscribed(window.chef.account, true),
		}
    }

    async processRequest_userInfo(_data)
    {
        this.userDeposit 	        = _data.userInfo[0];
		this.userBalance 	        = _data.userInfo[1];
        this.userClaimed 	        = _data.userInfo[2];
        this.userClaimedSoft        = _data.userInfo[3];
        this.userClaimedHard        = _data.userInfo[4];
        this.userClaimableSoft      = _data.userClaimableSoft;
        this.userClaimableHard      = _data.userClaimableHard;
        this.userWithdrawableSoft   = _data.userWithdrawableSoft;
        this.userWithdrawableHard   = _data.userWithdrawableHard;

		//complete
		this.initializedUser = true

		//event
        document.dispatchEvent(new CustomEvent('launchpad_userInfo',
        {
            detail:
            {
                address: this.address
            }
        }))
    }

	async reloadUserInfo()
	{
		//lazy init
		await this.init()
		if (!this.initialized)
		{
			return
		}

		//handle result
        const [ret] = await LWeb3.tryMultiCall(
			window.chef.makeMultiCall(true),
			[
				this.makeRequest_userInfo()
			],
			this.debugErrorString("userInfo"),
			"Launchpad: userInfo")
        this.processRequest_userInfo(ret[0])        
	}

	////////////////////////////////////	

	async approve()
	{
		await this.depositToken.approve(this.address)
	}

    async approveReward()
	{
		await this.rewardToken.approve(this.address)
	}

	async deposit(_amount)
	{        
		const amountStr = LWeb3.smartFormatTokens(window.chef.toBN(_amount), this.depositToken, true)
        const con = this.getContract(true);
		await window.chef.trySend(con.methods.deposit(_amount), window.chef.account, this.debugErrorString("deposit"), undefined, `Deposit ${amountStr} ${this.depositToken.getFullName()}`)
	}

	async claimSoftCap()
	{
        const con = this.getContract(true);
        await window.chef.trySend(con.methods.claimSoftCap(), window.chef.account, this.debugErrorString("claimSoft"), undefined, `Claim SoftCap`)
	}

    async claimHardCap()
	{
        const con = this.getContract(true);
        await window.chef.trySend(con.methods.claimHardCap(), window.chef.account, this.debugErrorString("claimHard"), undefined, `Claim HardCap`)
	}

    async depositRewards()
	{
        const con = this.getContract(true);
        await window.chef.trySend(con.methods.depositRewardTokens(), window.chef.account, this.debugErrorString("depositRewardTokens"), undefined, `Deposit Rewards`)
	}    

	////////////////////////////////////
}

export default Launchpad;