//libs
import {LLib} from '../libs/LLib.js'
import {LWeb3} from '../libs/LWeb3.js'

//contracts
import {ABI_MoonPool} from '../contracts/MoonPool'

class MoonPool
{
	////////////////////////////////////

	constructor(_address)
	{
		//init
		this.initialized = false
		this.initializedInfo = false
		this.initializedUser = false
		
		//values
		this.address = _address
		this.versionString = "0.0"
		this.version = LLib.getVersion(this.versionString)
		this.percentFactor = 1000000
		
		//data
		this.isApproved = false
		this.depositToken = null
		this.rewardToken = null
		this.depositTokenAddress = ""
		this.rewardTokenAddress = ""
		this.startTime = 0
		this.endTime = 0
		this.startTimeUTC = null
		this.endTimeUTC = null
		this.totalClaimed = "0"
		this.totalReward = "0"
		this.refilledReward = "0"
		this.rewardPerSecond = "0"
		this.rewardPerMoonPerDay = "0"
		this.userPending = "0"
		this.userPendingUSD = "0"
		this.userDeposit = "0"
		this.totalDeposit = "0"
		this.remainingReward = "0"
		this.currentRemainingReward = "0"
		this.userShare = 0
		this.userClaimed = "0"
		this.userClaimedUSD = "0"
	}

	////////////////////////////////////
	
	debugErrorString(_text)
	{
		return "MoonPool failed at: " + _text		
	}

	getContract(_user)
    {       
        let web3 = window.chef.selectWeb3Connection(_user)
        let con = new web3.eth.Contract(ABI_MoonPool, this.address)
        return con
    }

	isValidChain()
	{
		return window.chef.moonChef.isValidChain()
	}

	////////////////////////////////////

	async init()
	{
		if (this.initialized
			|| !this.isValidChain())
		{
			return
		}

		//make multicall
        let con = this.getContract(false)
        let calls =
        [ 
            {
				percentFactor: con.methods.PERCENT_FACTOR(),
				versionString: con.methods.VERSION(),
                depositTokenAddress: con.methods.stakedToken(),
                rewardTokenAddress: con.methods.rewardToken(),
                startTime: con.methods.startTime(),
				endTime: con.methods.endTime(),
				totalDeposit: con.methods.balance(),
				refilledReward: con.methods.refilledReward(),
				remainingReward: con.methods.remainingReward(),
				currentRemainingReward: con.methods.getCurrentRemainingReward(),
				rewardPerSecond: con.methods.rewardPerSecond(),
				rewardPerMoonPerDay: con.methods.rewardPerMoon(24 * 60 * 60),
            }
        ]

		//handle result
        const [ret] = await LWeb3.tryMultiCall(window.chef.makeMultiCall(false), calls, this.debugErrorString("init"), "MoonPool: init")
        const res = ret[0]
		this.percentFactor			= parseInt(res.percentFactor)
        this.versionString 			= res.versionString
		this.depositTokenAddress 	= res.depositTokenAddress;	
		this.rewardTokenAddress 	= res.rewardTokenAddress
		this.startTime				= parseInt(res.startTime)
		this.endTime				= parseInt(res.endTime)
		this.totalDeposit			= res.totalDeposit
		this.remainingReward		= res.remainingReward
		this.refilledReward			= res.refilledReward
		this.currentRemainingReward	= res.currentRemainingReward
		this.rewardPerSecond		= res.rewardPerSecond
		this.rewardPerMoonPerDay	= res.rewardPerMoonPerDay

		//process
		this.version = LLib.getVersion(this.versionString)
		this.depositToken = window.chef.findToken(this.depositTokenAddress)
		window.chef.addDepositToken(this.depositToken)
		this.rewardToken = window.chef.findToken(this.rewardTokenAddress)		
		this.startTimeUTC = new Date(this.startTime * 1000)
		this.endTimeUTC = new Date(this.endTime * 1000)		

		//complete
		this.initialized = true

		//event
        document.dispatchEvent(new CustomEvent('pool_init',
        {
            detail:
            {
                address: this.address
            }
        }))
	}

	async reloadData()
	{
		//lazy init
		this.init()
		if (!this.initialized)
		{
			return
		}

		//make multicall
        let con = this.getContract()
        let calls =
        [ 
            {
				startTime: con.methods.startTime(),
				endTime: con.methods.endTime(),
				totalDeposit: con.methods.balance(),
				totalReward: con.methods.totalReward(),
				remainingReward: con.methods.remainingReward(),
				refilledReward: con.methods.refilledReward(),
				currentRemainingReward: con.methods.getCurrentRemainingReward(),
				rewardPerSecond: con.methods.rewardPerSecond(),
				rewardPerMoonPerDay: con.methods.rewardPerMoon(24 * 60 * 60),
            }
        ]

		//handle result
        const [ret] = await LWeb3.tryMultiCall(window.chef.makeMultiCall(false), calls, this.debugErrorString("reloadData"), "MoonPool: reloadData")
        const res = ret[0]		
		this.startTime				= parseInt(res.startTime)
		this.endTime				= parseInt(res.endTime)
		this.totalDeposit			= res.totalDeposit
		this.totalReward			= res.totalReward
		this.remainingReward		= res.remainingReward
		this.refilledReward			= res.refilledReward
		this.currentRemainingReward	= res.currentRemainingReward
		this.rewardPerSecond		= res.rewardPerSecond
		this.rewardPerMoonPerDay	= res.rewardPerMoonPerDay

		//process		
		this.startTimeUTC = new Date(this.startTime * 1000)
		this.endTimeUTC = new Date(this.endTime * 1000)
		this.calculateStats();

		//complete
		this.initializedInfo = true

		//event
        document.dispatchEvent(new CustomEvent('pool_poolInfo',
        {
            detail:
            {
                address: this.address
            }
        }))
	}

	async reloadUserInfo()
	{
		//lazy init
		this.init()
		if (!this.initialized
			|| window.chef.account === null)
		{
			return
		}

		//make multicall
        let con = this.getContract()
        let calls =
        [ 
            {
				userPending: con.methods.pendingReward(window.chef.account),
				userDeposit: con.methods.balanceOf(window.chef.account),
				isApproved: con.methods.checkApproved(window.chef.account),
				userInfo: con.methods.getUserInfo(window.chef.account)
            }
        ]

		//handle result
        const [ret] = await LWeb3.tryMultiCall(window.chef.makeMultiCall(true), calls, this.debugErrorString("reloadData"), "MoonPool: reloadData")
        const res = ret[0]		
		this.userPending			= res.userPending
		this.userDeposit			= res.userDeposit
		this.userClaimed			= res.userInfo[1]
		this.isApproved				= res.isApproved

		//process
		this.userPendingUSD = this.rewardToken.getPriceUSDForAmount(this.userPending)
		this.userClaimedUSD = this.rewardToken.getPriceUSDForAmount(this.userClaimed)
		this.calculateStats();

		//complete
		this.initializedUser = true

		//event
        document.dispatchEvent(new CustomEvent('pool_userInfo',
        {
            detail:
            {
                address: this.address
            }
        }))
	}

	////////////////////////////////////

	async checkApprovedMoon()
	{
		return await this.moonToken.checkApproved(this.address)
	}

	async approve()
	{
		await this.depositToken.approve(this.address)
	}

	async claim()
	{
		let con = this.getContract(true)
		await window.chef.trySend(con.methods.claim(), window.chef.account, this.debugErrorString("claim"), undefined, `Claim ${this.rewardToken.getFullName()}`)
	}
	
	async withdrawAll()
	{
		let con = this.getContract(true)
		await window.chef.trySend(con.methods.withdraw(window.chef.web3_data.eth.abi.encodeParameter("int256", "-1")), window.chef.account, this.debugErrorString("withdraw"), undefined, `Withdraw All ${this.depositToken.getFullName()}`)
	}
	
	async withdraw(_amount)
	{
		let con = this.getContract(true)
		const amountStr = LWeb3.smartFormatTokens(window.chef.toBN(_amount), this.depositToken, true)
		await window.chef.trySend(con.methods.withdraw(_amount), window.chef.account, this.debugErrorString("withdraw"), undefined, `Withdraw ${amountStr} ${this.depositToken.getFullName()}`)
	}
	
	async deposit(_amount)
	{
		let con = this.getContract(true)
		const amountStr = LWeb3.smartFormatTokens(window.chef.toBN(_amount), this.depositToken, true)
		await window.chef.trySend(con.methods.deposit(_amount), window.chef.account, this.debugErrorString("deposit"), undefined, `Deposit ${amountStr} ${this.depositToken.getFullName()}`)
	}

	async swapForLiquidity(_token)
    {
        let con = this.getContract(true)
        await window.chef.trySend(
            con.methods.swapForLiquidity(
                _token.address),
            window.chef.account,
            this.debugErrorString("swapForLiquidity"))
    }

	async refill(_duration)
    {
        let con = this.getContract(true)
        await window.chef.trySend(
            con.methods.refill(
                _duration),
            window.chef.account,
            this.debugErrorString("refill"))
    }

	////////////////////////////////////

	getBalanceUSD()
	{
		const userBalance = window.chef.toBN(this.moonToken.userBalance)
		const circulatingSupply = window.chef.toBN(this.circulatingSupply)
		const totalBalance = window.chef.toBN(this.totalBalance)
	 	let balanceUSDValue = window.chef.toBN(0)
		if (circulatingSupply.cmp(window.chef.toBN(0)) !== 0)
		{
			balanceUSDValue = totalBalance.mul(userBalance).div(circulatingSupply)
		}
		const balanceUSD = balanceUSDValue.toString(10)
		return balanceUSD
	}

	calculateStats()
	{
		const pf = window.chef.toBN(this.percentFactor);
		const uD = window.chef.toBN(this.userDeposit);
		const tD = window.chef.toBN(this.totalDeposit);

		//user share
		this.userShare = parseInt(uD.mul(pf).div(tD).toString(10)) / this.percentFactor;
	}

	////////////////////////////////////
}

export default MoonPool;