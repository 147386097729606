import React from 'react';

//libs
import { LLib } from '../../../../../libs/LLib';

//components
import { Group, Text } from '../../../'

//css
import './ProgressBarVaultBreakEven.css';

class ProgressBarVaultBreakEven extends React.Component
{
	constructor(props)
	{   
		super(props)
		
		//init state
		this.state = 
		{
			vault: props.vault,
            withdraw: props.withdraw || false,
            showLabels: props.showLabels || false,
            hideIfBreakEven: false
		}	
	}

    makeGradient(_depositFee, _withdrawFee, _min, _max)
    {
        const range = (_max - _min)
        const p1 = (((1 - _min) / range) * 100).toFixed(0)
        const p2 = (((_withdrawFee - _min) / range) * 100).toFixed(0)

        let gradient = `linear-gradient(
            90deg,
            var(--progressBar_breakEven_red) 0%,
            var(--progressBar_breakEven_red) ${p1}%,
            var(--progressBar_breakEven_yellow) ${p1}%,
            var(--progressBar_breakEven_yellow) ${p2}%,
            var(--progressBar_breakEven_green) ${p2}%,
            var(--progressBar_breakEven_green) 100%)`

        return gradient
    }

    renderFeeTax(_label, _fee, _tax, _color)
    {
        const fee = (
            <Text size="-1" color={`var(--progressBar_breakEven_${_color})`}>
                {LLib.smartFormatPercent(_fee * 100, true)} fee
            </Text>
        )
        const tax = (
            <Text size="-1" color={`var(--progressBar_breakEven_${_color})`}>
                {LLib.smartFormatPercent(_tax * 100, true)} tax
            </Text>
        )

        return this.renderLegendContainer(
            _label,
            fee,
            tax,
            _color)
    }

    renderLegendContainer(_label, _text1, _text2, _color)
    {
        return (
            <Group className="legendContainer" style={{ borderColor: `var(--progressBar_breakEven_${_color})`}}>
                <Text size="-1" color={`var(--progressBar_breakEven_${_color})`}>
                    {_label}:
                </Text>
                {_text1}
                {_text2}
            </Group>
        )
    }
	
	render()
	{
		let cn = "ProgressBarVaultBreakEven " + (this.props.className || "")

        //get stats
        const vault = this.state.vault
        const depositFeeLimit = vault.totalDepositFeeTaxFactor
        const withdrawFeeLimit = vault.totalWithdrawFeeTaxFactor
        const current = (this.state.withdraw ? 1 + vault.userProfitLoss : depositFeeLimit)

        //get min/max/current
        let min = (this.state.withdraw ? Math.min(current, depositFeeLimit) : depositFeeLimit)
        let value = (this.state.withdraw ? current : depositFeeLimit)
        let max = (this.state.withdraw ? Math.max(current, 1.1) : 1.1)
        let style = 
        {
            background: this.makeGradient(depositFeeLimit, withdrawFeeLimit, min, max),
            height: "5px"
        }

        //check if display required
        if (value >= withdrawFeeLimit
            && this.state.hideIfBreakEven)
        {
            return null
        }

        let labelLegend = <></>
        let labelInfo = <></>
        if (this.state.showLabels)
        {
            let labelProfit = <></>
            if (current < withdrawFeeLimit)
            {
                //not full break even yet
                const days = Math.ceil((withdrawFeeLimit - current) / this.state.vault.dailyAPR)
                labelProfit = this.renderLegendContainer(
                    "break even",
                    <Text size="-1" color="var(--progressBar_breakEven_green)">in ~{isFinite(days) ? days : "???"} days</Text>,
                    <Text size="-1" color="var(--progressBar_breakEven_green)">at {LLib.smartFormatPercent(this.state.vault.dailyAPR * 100, true)} daily</Text>,
                    "green")
            }
            
            //legend
            let labelDepositFee = <></>
            if (depositFeeLimit !== 1
                && !this.state.withdraw)
            {
                labelDepositFee = this.renderFeeTax(
                    "deposit",
                    vault.depositFee,
                    vault.additionalDepositTax,
                    "red")
            }
            let labelWithdrawFee = <></>
            if (vault.withdrawFee !== 0
                || vault.additionalWithdrawTax !== 0)
            {
                labelWithdrawFee = this.renderFeeTax(
                    "withdraw",
                    vault.withdrawFee,
                    vault.additionalWithdrawTax,
                    "yellow")
            }

            labelLegend = (
                <>
                    {labelDepositFee}
                    {labelWithdrawFee}
                    {labelProfit}
                </>
            )
        }

        //fee disclaimer
        let disclaimer = <></>
        if (window.chef.vaultChef.withdrawFee > 0)
        {
            disclaimer = (
                <Text size="-2" color="2" italic="true">
                    (*) {LLib.smartFormatPercent(window.chef.vaultChef.withdrawFee * 100, true)} withdraw fee from Moon Vault
                </Text>
            )
        }

		return (
            <Group className={cn}>
                <Group className="legend">
                    {labelLegend}
                </Group>
                <Group className="info">
                    {labelInfo}
                </Group>
                <input                
                    type="range"
                    style={style}
                    readOnly={true}
                    step={0.01}
                    min={min}
                    max={max}
                    value={value} />                
                {disclaimer}                
            </Group>
        )
	}
}

export default ProgressBarVaultBreakEven;