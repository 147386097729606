import React from 'react';

//components
import { Group, Text, TokenIcon } from '../../..'

//css
import './PoolHeader.css'

class PoolHeader extends React.Component
{
	constructor(props)
	{   
		super(props);

        this.pool = props.pool

        this.showContract = (typeof props.showContract === 'boolean' ? props.showContract : true)
	} 

    render()
    {
        return (
			<Group className="PoolHeader">                
                <Group className="PoolHeaderGroup">
                    <TokenIcon token={this.pool.depositToken} />
                    <Group className="depositRewardInfo">
                        <Text className="deposit" color="1">      
                            Stake {this.pool.depositToken?.symbol}
                        </Text>
                        <Text className="reward" color="2">   
                            Earn {this.pool.rewardToken?.symbol}
                        </Text>
                    </Group>
                    <TokenIcon token={this.pool.rewardToken} />
                </Group>
            </Group>
		)
    }
}

export default PoolHeader;