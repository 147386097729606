//libs
import {LLib} from '../../libs/LLib'
import {LWeb3} from '../../libs/LWeb3'

//classes
import VaultChef from '../../classes/VaultChef'
import MoonChef from '../../classes/MoonChef'
import MoonPool from '../../classes/MoonPool'
import Launchpad from '../../classes/Launchpad'
import Vault from '../../classes/Vault'

//components
import AppChef_base from './AppChef_base'

//modals
import ModalWelcome from '../Modals/AddOn/ModalWelcome/ModalWelcome'
import ModalMessage from '../Modals/ModalMessage/ModalMessage'

class AppChef extends AppChef_base
{
	constructor(props)
	{   
		super(props)

		//init
        this.moonConfig = null
        this.platforms = []    
        this.user = null

        //classes
        this.vaultChef = null
        this.moonChef = null
        this.moonPool = null
        this.launchpad = null
        this.vaults = []

        //runtime data
        this.userVotingPower = 0      
        this.userVotingBreakdown = null;  

        //stats        
        this.minAPR = 0
        this.maxAPR = 0
        this.totalDepositUSD = 0
        this.totalPendingUSD = 0
        this.userDepositUSD = 0
        this.userPendingUSD = 0
		this.roiDailyAPR = 0
		this.roiWeeklyAPR = 0
		this.roiMonthlyAPR = 0
		this.roiYearlyAPR = 0
		this.roiDailyAPY = 0
		this.roiWeeklyAPY = 0
		this.roiMonthlyAPY = 0
		this.roiYearlyAPY = 0
        this.avgDailyAPR = 0
        this.avgYearlyAPR = 0
        this.avgYearlyAPY = 0
        this.totalDailyROI = 0
        this.totalDailyUSD = 0
        this.totalDailyProtocolUSD = 0

        //APIs
        this.api_url = '/api.php'
        if (window.location.hostname.includes("localhost")
            || window.location.port === "3000")
        {
            this.api_url = 'http://dev.moon-vault.com/api.php'
        }
	}

    async refreshChainData()
    { 
        //user login (just keep alive every 5 minutes)
        if (this.user !== null
            && !window.location.hostname.includes("localhost"))
        {
            this.refreshUserData()
        }

        //base
        await super.refreshChainData() 

        //vaults
        await this.refreshData_vaults()               

        //moon chef
        if (this.moonChef !== null)
        {
            await this.moonChef.reloadData()
        }

        //moon pool
        if (this.moonPool !== null)
        {
            await this.moonPool.reloadData()
            if (this.account !== null)
            {
                await this.moonPool.reloadUserInfo()
            }
        }

        //launchpad
        if (this.launchpad !== null)
        {
            await this.launchpad.reloadInfo()
            if (this.account !== null)
            {
                await this.launchpad.reloadUserInfo()
            }
        }
    }

    async refreshData_vaults()
    {
        //init vaults
        let vaultsInit
        await LLib.measureTime(`TM: VaultInit ${this.vaults.length}`, async () => 
        {
            vaultsInit = await Vault.batchInit(this.vaults)
        })  

        //load vault info
        await LLib.measureTime(`TM: VaultData ${vaultsInit.length}`, async () => 
        {
            await Vault.batchLoadVaultInfo(vaultsInit)
        })

        //get DB infos
        await LLib.measureTime(`TM: VaultDB ${vaultsInit.length}`, async () => 
        {                
            await Vault.batchReloadDBInfo()
        })
        
        //load user info
        if (this.account !== null)
        {
            await LLib.measureTime(`TM: VaultUserData ${vaultsInit.length}`, async () => 
            {
                await Vault.batchLoadUserInfo(vaultsInit)
            })            
        }

        //calc
        this.calculateVaultStats()
    }

    calculateVaultStats()
    {
        //init
        let minAPR = 0
        let maxAPR = 0
        let totalDepositUSD = this.toBN(0)
        let totalPendingUSD = this.toBN(0)
        let userDepositUSD = this.toBN(0)
        let userPendingUSD = this.toBN(0)
		let roiDailyAPR = 0
		let roiWeeklyAPR = 0
		let roiMonthlyAPR = 0
		let roiYearlyAPR = 0
		let roiDailyAPY = 0
		let roiWeeklyAPY = 0
		let roiMonthlyAPY = 0
		let roiYearlyAPY = 0        
        let totalDailyUSD = this.toBN(0)

        //handle vaults
        for (let n = 0; n < this.vaults.length; n++)
        {
            let v = this.vaults[n]

            //process apr
            maxAPR = Math.max(maxAPR, v.combinedDailyAPR)
            minAPR = (minAPR === 0 ? v.combinedDailyAPR : Math.min(minAPR, v.combinedDailyAPR))

            //process total
            totalDepositUSD = totalDepositUSD.add(this.toBN(v.totalDepositUSD))
            totalPendingUSD = totalPendingUSD.add(this.toBN(v.totalPendingUSD))
            const vaultDailyUSD = this.toBN(v.totalDepositUSD).mul(this.toBN(parseInt(v.dailyAPR * this.vaultChef.percentFactor))).div(this.toBN(this.vaultChef.percentFactor))
            totalDailyUSD = totalDailyUSD.add(vaultDailyUSD)

            //process user
            userDepositUSD = userDepositUSD.add(this.toBN(v.userDepositUSD))
            userPendingUSD = userPendingUSD.add(this.toBN(v.userPendingUSD))
            roiDailyAPR += v.roiDailyAPR
            roiWeeklyAPR += v.roiWeeklyAPR
            roiMonthlyAPR += v.roiMonthlyAPR
            roiYearlyAPR += v.roiYearlyAPR
            roiDailyAPY += v.roiDailyAPY
            roiWeeklyAPY += v.roiWeeklyAPY
            roiMonthlyAPY += v.roiMonthlyAPY
            roiYearlyAPY += v.roiYearlyAPY
        }

        //set values
        this.maxAPR = maxAPR
        this.minAPR = minAPR
        this.totalDepositUSD = totalDepositUSD.toString(10)
        this.totalPendingUSD = totalPendingUSD.toString(10)
        this.userDepositUSD = userDepositUSD.toString(10)
        this.userPendingUSD = userPendingUSD.toString(10)
        this.roiDailyAPR = roiDailyAPR
		this.roiWeeklyAPR = roiWeeklyAPR
		this.roiMonthlyAPR = roiMonthlyAPR
		this.roiYearlyAPR = roiYearlyAPR
		this.roiDailyAPY = roiDailyAPY
		this.roiWeeklyAPY = roiWeeklyAPY
		this.roiMonthlyAPY = roiMonthlyAPY
		this.roiYearlyAPY = roiYearlyAPY

        const uDeposit = parseFloat(LWeb3.fullFormatTokens(this.userDepositUSD, this.stableToken))
        this.avgDailyAPR = this.roiDailyAPR / (uDeposit === 0 ? 1 : uDeposit)
        this.avgYearlyAPR = this.roiYearlyAPR / (uDeposit === 0 ? 1 : uDeposit)
        this.avgYearlyAPY = this.roiYearlyAPY / (uDeposit === 0 ? 1 : uDeposit)

        //total profits
        if (totalDailyUSD.cmp(this.toBN(0)) === 0)
        {
            this.totalDailyUSD = "0"
            this.totalDailyProtocolUSD = "0"
            this.totalDailyROI = 0
        }
        else
        {
            const protocolFee = this.toBN(parseInt((this.vaultChef.nativeLiquidityFee + this.vaultChef.nativePoolFee) * this.vaultChef.percentFactor))
            const pDailyROI = totalDailyUSD.mul(this.toBN(this.vaultChef.percentFactor)).div(totalDepositUSD).toString(10)
            this.totalDailyProtocolUSD = totalDailyUSD.mul(protocolFee).div(this.toBN(this.vaultChef.percentFactor)).toString(10)
            this.totalDailyUSD = totalDailyUSD.toString(10)            
            this.totalDailyROI = parseFloat(pDailyROI) / this.vaultChef.percentFactor
        }
    }

    async initChainData()
    {
        //init vault chef
        this.vaultChef = new VaultChef(this.currentChain.vaultChef)      
        try
        {  
            await this.vaultChef.init()
            await this.vaultChef.reloadUserData()
        }
        catch (e) {}

        //base
        await super.initChainData()

        //init vaults
        const jsonVaults = await LLib.fetchJSON(`./data/${this.currentChain.name}/vaults.json?v=${this.dataVersion}`)
        for (let n = 0; n < jsonVaults.length; n++)
        {
            let p = jsonVaults[n]
            let platform =
            {
                ...p,
                vaults: []
            }
            this.platforms.push(platform)
            for (let m = 0; m < p.vaults.length; m++)
            {
                let v = p.vaults[m]
                let vault = new Vault(v, platform)
                platform.vaults.push(vault)
                this.vaults.push(vault)
            }
        }
    }

    async initApp()
    {	
		//get config	
		this.moonConfig = await LLib.fetchJSON(`./data/moonConfig.json?v=${this.dataVersion}`)
		this.defaultChain = this.moonConfig.moonChain

        await super.initApp()
    }

    async initComplete()
    {
        //init moon chef
        this.moonChef = new MoonChef()
        try
        {
            await this.moonChef.init();
        }
        catch (e) { }

        //init moon pool
        this.moonPool = new MoonPool(this.moonConfig.moonPool)
        try
        {
            await this.moonPool.init();
        }
        catch (e) { }

        //init launchpad
        if (!!this.currentChain.launchpad)
        {
            this.launchpad = new Launchpad(this.currentChain.launchpad)
            try
            {
                await this.launchpad.init();
            }
            catch (e) { }
        }
        
        //complete
        await this.refreshUserData()
        await super.initComplete()

        //intro / MotD
        ModalWelcome.showModal().then(() =>
        {
            //complete
            this.getMessage('MotD', (_msg) =>
            {
                if (_msg
                    && _msg.id > -1)
                {
                    ModalMessage.showModal(_msg.title, _msg.text)
                }
            })
        });
    }

    isSpecialToken(_token)
    {
        return LWeb3.checkEqualAddress(_token.address, this.moonChef?.moonToken?.address)
    }
	
	findVault(_id)
	{
        let vault = this.vaults.find((v) => v.id === _id)
        return (vault || null)
	}

    findVaultPlatform(_id)
	{
        let platform = this.platforms.find((p) => p.name === _id)
        return (platform || null)
	}

    async getMessage(_category, _onResult)
    {
        let msg = null
        try
        {
            msg = await LLib.fetchJSON(this.api_url + `?module=protocol&action=getMessage&chain=${this.currentChain.id}&category=${_category}`)
        }
        catch (e)
        {
            return
        }

        if (_onResult)
        {
            _onResult(msg)
        }
    }

    async refreshUserData()
    {
        const user = await LLib.fetchJSON(this.api_url + "?module=user&action=getUserData")
        if (this.user?.id !== user?.id)
        {
            this.user = (user.id === -1 ? null : user);

            //event
            document.dispatchEvent(new CustomEvent('user_userInfo'));
        }

        //try get voting power
		try
		{
			const result = await LLib.fetchJSON(this.api_url + `?module=governance&addresses=${this.account}&reload=true&breakdown=true`);
			this.userVotingPower = parseFloat(result.score[0].score) / 1000000;
            this.userVotingBreakdown = result.score[0].scoreBreakdown;
		}
		catch (e) { }
    }

    async login(_user, _password)
    {
        let fd = new FormData()
        fd.append('user', _user)
        fd.append('password', _password)
		const user = await LLib.fetchJSONFromForm(window.chef.api_url + "?module=user&action=login", fd)
        this.user = user
        
        //event
        document.dispatchEvent(new CustomEvent('user_userInfo'))
    }

    async logout(_user, _password)
    {
        await LLib.fetchJSON(this.api_url + "?module=user&action=logout")
		this.user = null

        //event
        document.dispatchEvent(new CustomEvent('user_userInfo'))
    }

    isLoggedIn()
    {
        return (this.user !== null && this.user.id !== -1);
    }
}

export default AppChef;