import React from 'react';

//libs
import {LLib, LWeb3} from '../../../../../libs'

//components
import { Button, Group, Text } from '../../..'
import { PoolHeader } from '../..'

//css
import './PoolView.css'

class PoolView extends React.Component
{
	constructor(props)
	{   
		super(props)

        //init state
		this.state = 
		{
            updateRevision: 0
		}
		
		//init
		this.id = props.id
        this.refPoolModalManager = props.refPoolModalManager
        this.pool = props.pool

        this.checkUpdate_token = this.checkUpdate_token.bind(this)
        this.checkUpdate_pool = this.checkUpdate_pool.bind(this)
	}

    componentDidMount()
	{	
        document.addEventListener('token_userInfo', this.checkUpdate_token)
        document.addEventListener('pool_poolInfo', this.checkUpdate_pool)
        document.addEventListener('pool_userInfo', this.checkUpdate_pool);  
	}

    componentWillUnmount()
	{	
        document.removeEventListener('token_userInfo', this.checkUpdate_token)
        document.removeEventListener('pool_poolInfo', this.checkUpdate_pool)
        document.removeEventListener('pool_userInfo', this.checkUpdate_pool)
	}

    updateView()
	{
		this.setState({ updateRevision: this.state.updateRevision + 1 })
	}

    checkUpdate_token(_data)
    {
        if (LWeb3.checkEqualAddress(this.pool.depositToken.address, _data.detail.address))
        {
            this.updateView()
        }
    }

    checkUpdate_pool(_data)
    {
        if (LWeb3.checkEqualAddress(this.pool.address, _data.detail.address))
        {
            this.updateView()
        }
    }

    async onClick_deposit()
    {
        if (!this.pool.initializedUser
            || !this.pool.isApproved)
        {
            this.refPoolModalManager.current.showPoolDialog("approve", this.pool)
            return
        }

        this.refPoolModalManager.current.showPoolDialog("deposit", this.pool)
    }

    async onClick_withdraw()
    {
        this.refPoolModalManager.current.showPoolDialog("withdraw", this.pool)
    } 
    
    async onClick_claim()
    {
        await this.pool.claim()
    } 

    renderNameValueGroup(_id, _name, _value, _disabledColor, _value2, _icon)
    {
        let content = (
            <Text color={!!_disabledColor ? "0" : "2"}>
                {_value}
            </Text>
        )

        return this.renderNameContentGroup(_id, _name, content, _value2, _icon)
    }

    renderNameContentGroup(_id, _name, _content, _content2, _icon)
    {
        //content 2
        let c2 = null
        if (_content2 !== undefined)
        {
            c2 = (
                <Group className="groupValue2">
                    {_content2}
                </Group>
            )
        }

        //content group
        return (
            <Group className={"PoolInfoGroup " + _id}>
                <Group className="groupName">
                    <Text color="1">
                        {_name}
                        {_icon ?? <></>}
                    </Text>
                </Group>
                <Group className="groupValue">
                    {_content}
                </Group>
                {c2}
            </Group>
        )
    }

    renderDepositWithdraw()
    {
        //get token balance
        let depositTokenBalance = this.pool.depositToken?.userBalance || "0"        
        let userDeposit = this.pool.userDeposit || "0"   

        return (
            <Group className="depositWithdraw">                
                <Button
                    buttonStyle={depositTokenBalance === "0" ? "0" : "1"}
                    onClick={() => this.onClick_deposit()}>
                    Deposit
                </Button>
                <Button
                    buttonStyle={userDeposit === "0" ? "0" : "1"}
                    onClick={() => this.onClick_withdraw()}>
                    Withdraw
                </Button>
            </Group>
        )
    }

    renderTotalDeposit()
    {
        return this.renderNameValueGroup(
            "ttl",
            "TTL",
            LLib.renderLoading(
                this.pool.initializedInfo, 
                LWeb3.smartFormatTokens(
                    this.pool.totalDeposit, 
                    this.pool.depositToken, 
                    true)),
            this.pool.initializedInfo && this.pool.totalDeposit === "0")
    }

    renderClaim()
    {
        return (
            <Group className="claim">
                <Button
                    buttonStyle={this.pool.userPending === "0" ? "0" : "1"}
                    onClick={() => this.onClick_claim()}>
                    Claim
                    <br />
                    ~ {LWeb3.formatFiatDisplay(this.pool.userPendingUSD)}
                </Button>
            </Group>
        )
    }

    renderTotalPending()
    {
        return this.renderNameValueGroup(
            "totalPending",
            "total pending",
            LLib.renderLoading(
                this.pool.initializedInfo, 
                LWeb3.smartFormatTokens(
                    this.pool.totalPending, 
                    this.pool.rewardToken, 
                    true)),                    
            this.pool.initializedInfo && this.pool.totalPending === "0")
    }

    renderUserDeposit()
    {
        //user deposit
        return this.renderNameValueGroup(
            "userDeposit",
            "deposit",
            LLib.renderLoading(
                this.pool.initializedUser,
                LWeb3.smartFormatTokensDisplay(
                    this.pool.userDeposit, 
                    this.pool.depositToken, 
                    true)),
            this.pool.initializedUser && this.pool.userDeposit === "0",
            <Text color="0" size="-1">{LLib.smartFormatPercent(this.pool.userShare * 100, true, 0, 4)}</Text>)
    }

    renderUserPending()
    {
        let claimed = <Text size="-1" color="0">0 claimed</Text>
        if (this.pool.userClaimed !== "0")
        {
            claimed = (
                <Text size="-1" color="0" className="profit">
                    +{LWeb3.smartFormatTokens(this.pool.userClaimed, this.pool.rewardToken, true)}
                </Text>
            )
        }

        return this.renderNameValueGroup(
            "userPending",
            "pending",
            LLib.renderLoading(
                this.pool.initializedUser, 
                LWeb3.smartFormatTokens(
                    this.pool.userPending, 
                    this.pool.rewardToken, 
                    true)),
            this.pool.initializedUser && this.pool.userPending === "0",
            claimed)
    }

    renderTotalReward()
    {
        return this.renderNameValueGroup(
            "totalReward",
            "Total Rewards",
            LLib.renderLoading(
                this.pool.initialized, 
                LWeb3.smartFormatTokens(
                    this.pool.totalReward, 
                    this.pool.rewardToken, 
                    true)),
            this.pool.totalReward === "0")
    }

    renderRewardPerMoon()
    {
        //user deposit or 1000
        let estimate;
        let value2 = <></>
        const rmd = window.chef.toBN(this.pool.rewardPerMoonPerDay)
        if (this.pool.rewardPerMoonPerDay === "0"
            || this.pool.userDeposit === "0")
        {
            estimate = rmd.mul(this.pool.depositToken.one.mul(window.chef.toBN(1000))).div(this.pool.depositToken.one).toString(10)
            value2 = `per 1000 ${this.pool.depositToken.symbol}`
        }
        else
        {
            //user
            const uD = window.chef.toBN(this.pool.userDeposit)
            estimate = rmd.mul(uD).div(this.pool.depositToken.one).toString(10)
            value2 = `for deposit`
        }

        return this.renderNameValueGroup(
            "rewardPerMoon",
            "Estimate daily",
            LLib.renderLoading(
                this.pool.initialized, 
                LWeb3.smartFormatTokens(
                    estimate, 
                    this.pool.rewardToken, 
                    true)),
                estimate === "0",
                <Text color="0" size="-1">{value2}</Text>)
    }

    renderCurrentRemainingReward()
    {
        return this.renderNameValueGroup(
            "currentRemainingReward",
            "Rewards",
            LLib.renderLoading(
                this.pool.initialized, 
                LWeb3.smartFormatTokens(
                    this.pool.currentRemainingReward, 
                    this.pool.rewardToken, 
                    true)),
            this.pool.currentRemainingReward === "0",
            (
                <Text color="0" size="-1">
                    {LWeb3.smartFormatTokens(
                        this.pool.refilledReward, 
                        this.pool.rewardToken, 
                        true)}
                </Text>
            ))
    }

    renderPoolTime()
    {
        let eventInfo = <></>
        let eventInfo2 = <></>
        const now = (new Date()).getTime() / 1000
        const start = this.pool.startTimeUTC.getTime() / 1000
        const end = this.pool.endTimeUTC.getTime() / 1000
        let active = false
        if (now < start)
        {
            eventInfo = LLib.formatDate(this.pool.startTimeUTC)
            eventInfo2 = <Text color="0" size="0">{LLib.formatTime(this.pool.startTimeUTC)}</Text>
        }
        else if (now > end)
        {
            eventInfo = "finished"
        }
        else
        {
            active = true
            eventInfo = LLib.getHMS(end - now, true)
            eventInfo2 = <Text color="0" size="-1">until end</Text>
        }

        return this.renderNameValueGroup(
            "poolTime",
            "Event",
            LLib.renderLoading(
                this.pool.initialized, 
                eventInfo),
            !active,
            eventInfo2)
    }

    renderPoolInfo()
    {
        return (
            <PoolHeader pool={this.pool} showContract={false} />
        )
    }

    render()
    {
        let cn = "PoolView " + (this.props.className || "")

        return (
			<Group className={cn}>

                {this.renderPoolInfo()}
             
                {this.renderUserDeposit()}
                {this.renderUserPending()}

                {this.renderPoolTime()}
                {this.renderCurrentRemainingReward()}
                {this.renderRewardPerMoon()}  

                {this.renderDepositWithdraw()}
                {this.renderClaim()}

			</Group>
		)
    }
}

export default PoolView;