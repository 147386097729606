import { uniq, sortBy, findLastIndex } from 'lodash';

//libs
import {LLib} from '../../../../../../libs/LLib';

//components
import { Chart } from '../../../../';

const MissingData = {
	DefaultToZero: 0,
	FillIn: 1,
	Ignore: 2
}

class ChartProtocolTvl extends Chart
{
	constructor(props)
	{   
		super(props)
		
		//init state
		this.state =
		{
			...this.state,
			metrics: [],
			options: this.initChart(),
			missingDataStart: props.missingDataStart ?? MissingData.DefaultToZero,
			missingDataMiddle: props.missingDataMiddle ?? MissingData.FillIn,
			missingDataEnd: props.missingDataEnd ?? MissingData.FillIn,
			days: props.days || 180,
			stacked: false
		}
		
		this.reloadData = this.reloadData.bind(this)
	}
	
	async componentDidMount()
	{
		this.setState({ options: this.initChart() })
		await this.reloadData()
		this.interval = setInterval(this.reloadData, 600000)	
		document.addEventListener('chef_dataLoaded', this.reloadData); 	
	}
	
	componentWillUnmount()
	{			
		clearInterval(this.interval)
		document.removeEventListener('chef_dataLoaded', this.reloadData); 	
	}
	
	async reloadData()	
	{
		//metrics		
		let metrics = this.state.metrics
		try
		{
			metrics = await LLib.fetchJSON(window.chef.api_url + "?module=protocol&action=getProtocolMetrics&days=" + this.state.days)
		}
		catch (e) { }
		
		//fill data
		this.processData(metrics)
	}
	
	initChart(_tvl)
	{		
		//init
		let colors = []		
		for (let n = 0; n < window.chef.chains.length; n++)
		{
			colors.push(window.chef.chains[n].color)
		}
		
		//set options
		let opts = this.makeOptions()	
		this.setLabels()
		this.setType(opts, (this.state.stacked ? "area" : "line"))
		this.setTitle(opts, "TVL:" + (_tvl !== undefined ? (" " + LLib.formatFiat(_tvl, true, true)) : ""))
		this.setColors(opts, colors)
		opts.chart = 
		{
			...opts.chart,
			stacked: this.state.stacked,
			sparkline:
			{
				enabled: !this.showLabels
			},
			zoom:
			{
				type: 'x'
			}
		}
		opts.xaxis =
		{
			...opts.xaxis,
			labels:
			{
				...opts.xaxis.labels,
				show: false,
			}
		}
		opts.yaxis =
		{
			...opts.yaxis,
			show: this.showLabels,			
			labels:
			{
				formatter: function(_value)
				{
					if (isNaN(_value))
						return null;

					return LLib.formatFiat(_value);
				}
			}
		}
		opts.stroke =
		{
			...opts.stroke,
            curve: "straight",
        }		
		opts.tooltip = 
		{
			...opts.tooltip,
			x:
			{
				...opts.tooltip.x,
				show: true,
			},
			y:
			{
				formatter: val => LLib.formatFiat(val, true, { shorten: true })
			}
		}
		opts.legend = 
		{
			...opts.legend,
			show: false
		}
		if (this.state.stacked)
		{		
			opts.fill = 
			{
				...opts.fill,
				type: 'gradient',
                gradient:
				{
                  	opacityFrom: 0.6,
                  	opacityTo: 1,
                }
			}
		}
		return opts	
	}
	
	async processData(_metrics)
	{
		if (_metrics === undefined)
		{
			return
		}

		const formatDate = (d) => {
			return new Date(LLib.getDateTimeFromDB(d.dateTime).toDateString()).getTime();
		};

		const rawDates = _metrics
			.map(m => m.data)
			.reduce((accum, curr) => {
				return accum.concat(curr.map(d => formatDate(d)));
			}, []);
		let allDates = uniq(sortBy(rawDates));
		
		let sumTVL = 0
		let series = []
		let categories = []
		for (let n = 0; n < _metrics.length; n++)
		{
			let met = _metrics[n]
			let chain = window.chef.findChain(parseInt(met.chain));
			if (chain !== null)
			{
				let data = [];
				let cats = [];
				let previousValue = 0,
					pointsToFill = 0;
				let dataStarted = false;

				for (let i = 0, uniqueDate; uniqueDate = allDates[i]; i++)
				{
					cats.push(uniqueDate);

					let a = findLastIndex(met.data, a => formatDate(a) === uniqueDate);

					if (a === -1) {
						pointsToFill++;
					} else {
						let metD = met.data[a];

						if (dataStarted)
						{
							// Gap in the middle
							if (this.state.missingDataMiddle !== MissingData.Ignore && pointsToFill > 0) 
							{
								if (data.length) {
									let missingPoint = null;
									if (this.state.missingDataMiddle === MissingData.DefaultToZero) 
									{
										missingPoint = 0;
									}
									else
									{
										missingPoint = previousValue;
									}

									[...Array(pointsToFill)].forEach( _ => data.push(missingPoint));	
								} else {
									if (this.state.missingDataMiddle === MissingData.DefaultToZero)
									{
										[...Array(pointsToFill)].forEach( _ => data.push(0));
									}
									else
									{
										let variance = (metD.TVL - previousValue) / (pointsToFill + 1);
										[...Array(pointsToFill)].forEach( _ => data.push(previousValue = previousValue + variance));
									}
								}
							}
						} 
						else
						{
							// Gap at the start
							if (this.state.missingDataStart !== MissingData.Ignore && pointsToFill > 0) {
								if (data.length) {
									let missingPoint = null;
									if (this.state.missingDataStart === MissingData.DefaultToZero) {
										missingPoint = 0;
									}

									[...Array(pointsToFill)].forEach( _ => data.push(missingPoint));	
								} else {
									if (this.state.missingDataStart === MissingData.DefaultToZero)
									{
										[...Array(pointsToFill)].forEach( _ => data.push(0));
									}
									else
									{
										let variance = (metD.TVL - previousValue) / (pointsToFill + 1);
										[...Array(pointsToFill)].forEach( _ => data.push(previousValue = previousValue + variance));
									}
								}
							}
						}

						data.push(parseFloat(metD.TVL));
						
						//Track this value in case we need to fill missing points after this
						previousValue = parseFloat(metD.TVL);
						dataStarted = true;
						pointsToFill = 0;
					}
				}

				if (this.state.missingDataEnd !== MissingData.Ignore && pointsToFill > 0) {
					let missingPoint = data[data.length - 1];
					if (this.state.missingDataEnd === MissingData.DefaultToZero) {
						missingPoint = 0;
					}

					//If we are ending with empty points, fill them with nulls
					[...Array(pointsToFill)].forEach( _ => data.push(missingPoint));
				}

				if (met.data.length > 0)
				{
					let metCur = met.data[met.data.length - 1]
					sumTVL += parseFloat(metCur.TVL)
				}
				
				series.push(
				{
					name: chain.name,
					data: data					
				})
				categories = cats
			}
		}		

		let opts = this.initChart(sumTVL)
		this.setSeries(opts, series, categories.map(c => LLib.formatDate(c)))
	}

	render()
    {
        return super.render()
	}
}

export { ChartProtocolTvl, MissingData };