import React from 'react';

//modals
import ModalVaultWithdraw from '../../../../Modals/AddOn/Vaults/ModalVaultWithdraw/ModalVaultWithdraw'
import ModalVaultDeposit from '../../../../Modals/AddOn/Vaults/ModalVaultDeposit/ModalVaultDeposit'
import ModalVaultApprove from '../../../../Modals/AddOn/Vaults/ModalVaultApprove/ModalVaultApprove'
import ModalProfitCalculator from '../../../../Modals/AddOn/ModalProfitCalculator/ModalProfitCalculator';

class VaultModalManager extends React.Component
{
	constructor(props)
	{   
		super(props)
		
		//init state
		this.state = 
		{
            showDialog_deposit: false,
            showDialog_withdraw: false,
            showDialog_approve: false,
            showDialog_profitCalculator: false,
            dialogVault: null
		}
	}

    closeVaultDialog()
    {
        this.setState(
        {
            showDialog_deposit: false,
            showDialog_withdraw: false,
            showDialog_approve: false,
            showDialog_profitCalculator: false,
            dialogVault: null
        })
    }

    showVaultDialog(_type, _vault)
    {
        this.setState(
        {
            showDialog_deposit: (_type === "deposit"),
            showDialog_withdraw: (_type === "withdraw"),
            showDialog_approve: (_type === "approve"),
            showDialog_profitCalculator: (_type === "calculator"),
            dialogVault: _vault
        })
    }

    render()
    {
        if (this.state.showDialog_withdraw)
        {
            return (
                <ModalVaultWithdraw
                    vault={this.state.dialogVault}
                    show={this.state.showDialog_withdraw}
                    onClose={() => this.closeVaultDialog()} />
            )
        }
        else if (this.state.showDialog_deposit)
        {
            return (
                <ModalVaultDeposit
                    vault={this.state.dialogVault}
                    show={this.state.showDialog_deposit}
                    onClose={() => this.closeVaultDialog()} />
            )
        }
        else if (this.state.showDialog_approve)
        {
            return (
                <ModalVaultApprove
                    vault={this.state.dialogVault}
                    show={this.state.showDialog_approve}
                    onClose={() => this.closeVaultDialog()} />
            )
        }
        else if (this.state.showDialog_profitCalculator) 
        {
            return (
                <ModalProfitCalculator
                    vault={this.state.dialogVault}
                    show={this.state.showDialog_profitCalculator}
                    onClose={() => this.closeVaultDialog()} />
            );
        }

        return null
    }
}

export default VaultModalManager;