import React from 'react';

//libs
import {LLib, LWeb3} from '../../../libs'

//components
import { Text, Group, Panel, Link } from '../../Controls'
import { PoolModalManager, PoolView } from '../../Controls/AddOn';

//css
import './PagePool.css'

class PagePool extends React.Component
{
	constructor(props)
	{   
		super(props)
		 
		//init state
		this.state = 
		{
			updateRevision: 0,
			dialogShow_approveMoon: false
		}
		this.lock_buySellInput = false

		this.updateView = this.updateView.bind(this)
		this.refPoolModalManager = React.createRef();
	}

	componentDidMount()
	{	
		document.addEventListener('chef_dataLoaded', this.updateView);
	}

	componentWillUnmount()
	{	
		document.removeEventListener('chef_dataLoaded', this.updateView);
	}

    updateView()
	{
		this.setState({ updateRevision: this.state.updateRevision + 1 })
	}

	closeDialog()
    {
        this.setState(
        {
            dialogShow_approveMoon: false,
            dialogShow_approvePegged: false
        })
    }

	getDialogRaw(_id)
	{
		const i = document.getElementById(_id)
		const v = i.value;
		return v;
	}

	getDialogTokensUint256(_id)
	{
		const v = LWeb3.tokensToUint256String(this.getDialogRaw(_id));
		const val = window.chef.web3_data.eth.abi.encodeParameter("uint256", v);
		
		return val;
	}

	renderStat(_title, _value)
	{
		return (
			<Group className="row">
				<div className="col-6">
					<Text size="-1" color="2">
						{_title}
					</Text>
				</div>
				<div className="col-6">
					<Text size="-1" color="2">
						{LLib.renderLoading(window.chef.moonPool.initialized, _value)}
					</Text>
				</div>
			</Group>
		)
	}

	renderPanelWrongChain()
	{
		let chain = window.chef.findChain(window.chef.moonChef?.chainID)

		return (
			<Group className="PagePool">
				<Panel className="Panel_Pool">
					<Group className="wrongChain">
						<Text color="2">
							Our token is deployed on&nbsp;					
						</Text>
						<Link onClick={() => LWeb3.switchChain(chain.id)}>
							{chain?.name}
						</Link>
						<Text color="2">
							.
							<br />
							Switch chain to interact or get more info.
						</Text>
					</Group>
				</Panel>
			</Group>
		)
	}

    render()
    {
		if (!window.chef.moonPool?.isValidChain())
		{
			return this.renderPanelWrongChain()
		}

        return (
			<Group className="PagePool">
				<PoolModalManager ref={this.refPoolModalManager} />
				<PoolView
					pool={window.chef.moonPool}
					refPoolModalManager={this.refPoolModalManager} />
			</Group>
		)
    }
}

export default PagePool;