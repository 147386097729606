
import React from 'react';

//components
import { Group, Panel, Text } from '../../Controls'
import { VaultList, VaultFilter } from '../../Controls/AddOn'

//css
import './PageVaults.css'

class PageVaults extends React.Component
{
	constructor(props)
	{   
		super(props)
		
		this.refVaultList = React.createRef()
	}

	onChangeFilter(_filter)
	{
		this.refVaultList.current.setFilter(_filter)
	}

    render()
    {
		if (window.chef.vaults.length === 0)
		{
			return (
				<Group className="Page_Vaults">
					<Panel className="noVaults">
						<Text color="2">
							We found no Vaults.
							<br />
							<br />
							Maybe you are connected to the wrong chain?
						</Text>
					</Panel>
				</Group>
			)			
		}

        return (
			<Group className="Page_Vaults">
				<Panel className="Panel_Filter">
					<VaultFilter
						onChangeFilter={(_f) => this.onChangeFilter(_f)} />
				</Panel>
				<VaultList
					ref={this.refVaultList} />
			</Group>
		)
    }
}

export default PageVaults;