import React from 'react';

//components
import { Group, Text } from '../../../'
import { VaultModalManager, VaultView } from '../../'

//css
import './VaultList.css'

class VaultList extends React.Component
{
	constructor(props)
	{   
		super(props)
		
		//init state
		this.state = 
		{
            updateRevision: 0,
            filter:
            {
				platformName: "",
				name: "",
				onlyDeposit: false,
				vaultType: "",
				asset: "",
                sortType: "Asset"
			}
		}

        this.updateList = this.updateList.bind(this)
        this.refVaultModalManager = React.createRef()
	}

    componentDidMount()
	{	
		this.updateList()
		document.addEventListener('chef_dataLoaded', this.updateList)
	}

    componentWillUnmount()
	{	
		document.removeEventListener('chef_dataLoaded', this.updateList)
	}

	updateList()
	{
		this.setState({ updateRevision: this.state.updateRevision + 1 })
	}

    setFilter(_filter)
    {
        this.setState({ filter: _filter })
    }

    filterVaults()
    {
        let filteredVaults = []
        if (window.chef.vaultChef === undefined)
        {
            return filteredVaults
        }
		
		//filter
		let filter = this.state.filter;
		for (let n = 0; n < window.chef.vaults.length; n++)
		{
			let vault = window.chef.vaults[n];

            //platformName
            if (filter.platformName !== ""
                && filter.platformName !== vault.platform.name)
            {
                continue;
            }
            
            //name
            if (filter.name !== ""
                && !vault.name.includes(filter.name.toUpperCase()))
            {
                continue;
            }
            
            //vaultType
            if ((filter.vaultType === "Pool"
                    && vault.isFarm())
                || (filter.vaultType === "Farm"
                    && !vault.isFarm())
                || (filter.vaultType === "Stable"
                    && !vault.hasStable()))
            {
                continue;
            }

            //deposit / balance
            if ((filter.hideZeroBalance
                    && !vault.userHasBalance())
                || ((filter.onlyDeposit
                        || window.chef.pseudoAccount)
                    && !vault.userHasDeposit()))
            {
                continue;
            }

            //asset
            if (filter.asset !== ""
                && !vault.hasDepositAsset(filter.asset))
            {
                continue;
            }

            //push
            filteredVaults.push(vault);
		}

        //sort
        switch (filter.sortType)
        {
            case "Asset":
                filteredVaults.sort(this.sortBy_asset);
                break;

            case "APY":
                filteredVaults.sort(this.sortBy_apy);
                break;

            case "TVL":
                filteredVaults.sort(this.sortBy_tvl);
                break;

            default:
                filteredVaults.sort(this.sortBy_asset);
                break;
        }
		
		return filteredVaults;
    }

    sortBy_apy(_a, _b)
    {
        return _b.combinedAPY - _a.combinedAPY;
    }

    sortBy_tvl(_a, _b)
    {
        return window.chef.toBN(_b.totalDepositUSD).cmp(window.chef.toBN(_a.totalDepositUSD));
    }

    sortBy_asset(_a, _b)
    {
        const aT = _a.depositToken;
        const bT = _b.depositToken;

        //asset type
        const a_assetType = (aT.isLPToken() ? 1 : 0);
        const b_assetType = (bT.isLPToken() ? 1 : 0);
        if (a_assetType !== b_assetType)
        {
            return a_assetType - b_assetType;
        }

        //check
        const a_symbol = aT.getSymbolSortString();
        const b_symbol = bT.getSymbolSortString();
        if (a_symbol < b_symbol)
        {
            return -1;
        }
        else if (a_symbol > b_symbol)
        {
            return 1;
        }

        return 0;
    }

    renderFilterResultEmpty()
    {
        return (
            <Group className="VaultList">
                <Text className="emptyFilter">
                    There are no results that match your current filter!
                </Text>
            </Group>
        )
    }

    render()
    {
        const vaults = this.filterVaults()
        if (vaults.length === 0)
        {
            return this.renderFilterResultEmpty()
        }

        let vaultIndex = 0
        const vaultList = vaults.map((v) =>
        {
            const even = (vaultIndex % 2 === 0)
            vaultIndex += 1
            return (
                <React.Fragment key={v.id.toString()}>
                    <VaultView
                        refVaultModalManager={this.refVaultModalManager}
                        id={v.id}
                        className={(even ? 'even' : 'odd')} />
                </React.Fragment>
            )
        })

        return (
            <>
                <VaultModalManager ref={this.refVaultModalManager} />
                <Group className="VaultList">
                    {vaultList}
                </Group>
            </>
		)
    }
}

export default VaultList;