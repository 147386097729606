import React from 'react';

//components
import { Group, Input, Text, CheckBox, Select } from '../../../'

//css
import './VaultFilter.css'

class VaultFilter extends React.Component
{
	constructor(props)
	{   
		super(props)
		
		//init state
		this.state = 
		{
            updateRevision: 0,
            onChangeFilter: props.onChangeFilter || null
		}

        this.filter = 
        {
            platformName: "",
            name: "",
            onlyDeposit: false,
            hideZeroBalance: false,
            vaultType: "",
            asset: "",
            sortType: "Asset"
        }
        this.updateList = this.updateList.bind(this);

        //refs
        this.refInputName = React.createRef();
        this.refCheckboxZeroBalance = React.createRef();
        this.refCheckboxDeposited = React.createRef();
        this.refSelectType = React.createRef();
        this.refSelectPlatform = React.createRef();
        this.refSelectAsset = React.createRef();
        this.refSelectSort = React.createRef();
	}

    componentDidMount()
	{	
		document.addEventListener('chef_dataLoaded', this.updateList) 
	}

	componentWillUnmount()
	{	
		document.removeEventListener('chef_dataLoaded', this.updateList) 
	}

	updateList()
	{
		this.setState({ updateRevision: this.state.updateRevision + 1 })
	}

    filterChanged()
    {
        //change filter
        this.filter.name = this.refInputName.current.getValue();
        this.filter.onlyDeposit = this.refCheckboxDeposited.current.isChecked();
        this.filter.hideZeroBalance = this.refCheckboxZeroBalance.current.isChecked();
        this.filter.platformName = this.refSelectPlatform.current.getValue();
        this.filter.vaultType = this.refSelectType.current.getValue();
        this.filter.asset = this.refSelectAsset.current.getValue();
        this.filter.sortType = this.refSelectSort.current.getValue();

        //call event
        if (this.state.onChangeFilter)
        {
            this.state.onChangeFilter(this.filter)
        }
    }

    render()
    {   
        //flat platform names
        let platformNames = [""]
        window.chef.platforms.forEach((p) => platformNames.push(p.name));

        //deposit assets
        let depositAssets = [""]
        window.chef.depositAssets.forEach((t) =>
        {
            depositAssets.push(
            {
                value: t.address,
                text: t.symbol,
                icon: t.icon
            })
        });

        return (
            <Group className="VaultFilter">
                <Group className="name">
                    <Text color="2" size="-1">
                        Name:
                    </Text>
                    <Input
                        ref={this.refInputName}
                        onChange={() => this.filterChanged()}
                        placeholder="filter by name" />
                </Group>

                <Group className="zeroBalance">
                    <Text color="2" size="-1">
                        Hide 0 Balances:
                    </Text>    
                    <CheckBox
                        ref={this.refCheckboxZeroBalance}
                        onChange={() => this.filterChanged()} />                
                </Group> 

                <Group className="deposited">
                    <Text color="2" size="-1">
                        Deposited:
                    </Text>  
                    <CheckBox
                        ref={this.refCheckboxDeposited}
                        onChange={() => this.filterChanged()} />                  
                </Group> 

                <Group className="platform">
                    <Text color="2" size="-1">
                        Platform:
                    </Text>
                    <Select
                        ref={this.refSelectPlatform}
                        options={platformNames}
                        onChange={() => this.filterChanged()}
                        placeholder="select platform" />
                </Group>

                <Group className="type">
                    <Text color="2" size="-1">
                        Type:
                    </Text>
                    <Select
                        ref={this.refSelectType}
                        options={["", "Pool", "Farm", "Stable"]}
                        onChange={() => this.filterChanged()}
                        placeholder="select type" />
                </Group>

                <Group className="asset">
                    <Text color="2" size="-1">
                        Asset:
                    </Text>
                    <Select
                        ref={this.refSelectAsset}
                        options={depositAssets}
                        onChange={() => this.filterChanged()}
                        placeholder="select asset" />
                </Group>

                <Group className="sortOrder">
                    <Text color="2" size="-1">
                        Sort By:
                    </Text>
                    <Select
                        ref={this.refSelectSort}
                        options={["Asset", "APY", "TVL"]}
                        onChange={() => this.filterChanged()}
                        placeholder="sort order" />
                </Group>
            </Group>
		)
    }
}

export default VaultFilter;