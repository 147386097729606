import React from 'react';

//modals
import ModalPoolWithdraw from '../../../../Modals/AddOn/Pool/ModalPoolWithdraw/ModalPoolWithdraw'
import ModalPoolDeposit from '../../../../Modals/AddOn/Pool/ModalPoolDeposit/ModalPoolDeposit'
import ModalPoolApprove from '../../../../Modals/AddOn/Pool/ModalPoolApprove/ModalPoolApprove'

class PoolModalManager extends React.Component
{
	constructor(props)
	{   
		super(props)
		
		//init state
		this.state = 
		{
            showDialog_deposit: false,
            showDialog_withdraw: false,
            showDialog_approve: false,
            dialogPool: null
		}
	}

    closePoolDialog()
    {
        this.setState(
        {
            showDialog_deposit: false,
            showDialog_withdraw: false,
            showDialog_approve: false,
            dialogPool: null
        })
    }

    showPoolDialog(_type, _pool)
    {
        this.setState(
        {
            showDialog_deposit: (_type === "deposit"),
            showDialog_withdraw: (_type === "withdraw"),
            showDialog_approve: (_type === "approve"),
            dialogPool: _pool
        })
    }

    render()
    {
        if (this.state.showDialog_withdraw)
        {
            return (
                <ModalPoolWithdraw
                    pool={this.state.dialogPool}
                    show={this.state.showDialog_withdraw}
                    onClose={() => this.closePoolDialog()} />
            )
        }
        else if (this.state.showDialog_deposit)
        {
            return (
                <ModalPoolDeposit
                    pool={this.state.dialogPool}
                    show={this.state.showDialog_deposit}
                    onClose={() => this.closePoolDialog()} />
            )
        }
        else if (this.state.showDialog_approve)
        {
            return (
                <ModalPoolApprove
                    pool={this.state.dialogPool}
                    show={this.state.showDialog_approve}
                    onClose={() => this.closePoolDialog()} />
            )
        }

        return null
    }
}

export default PoolModalManager;