import React from 'react';

//libs
import {LLib, LWeb3, LSymbols} from '../../../libs/'

//components
import { Text, Group, Panel, Button, Link, InputTokenAmount } from '../../Controls'
import {InputTokenAmountMoonVault} from '../../Controls/AddOn'
import { ChartNativePrice, ChartNativePeggedBalance, ChartNativeCirculatingSupply } from '../../Controls/AddOn'

//modals
import ModalMoonApprove from '../../Modals/AddOn/ModalMoonApprove/ModalMoonApprove'
import ModalNativeTokenBuySell from '../../Modals/AddOn/ModalNativeTokenBuySell/ModalNativeTokenBuySell';
import ModalMessage from '../../Modals/ModalMessage/ModalMessage'

//css
import './PageToken.css'

class PageToken extends React.Component
{
	constructor(props)
	{   
		super(props)
		 
		//init state
		this.state = 
		{
			updateRevision: 0,
			dialogShow_approveMoon: false,
			dialogShow_approvePegged: false,
			mode: "buy",
			warnBeforeAction: true
		}
		this.lock_buySellInput = false

		this.updateView = this.updateView.bind(this)
	}

	componentDidMount()
	{	
		document.addEventListener('chef_dataLoaded', this.updateView);
        document.addEventListener('moonChef_BuySell', this.updateView);
		document.addEventListener('moonChef_info', this.updateView);
	}

	componentWillUnmount()
	{	
		document.removeEventListener('chef_dataLoaded', this.updateView);
        document.removeEventListener('moonChef_BuySell', this.updateView);
		document.removeEventListener('moonChef_info', this.updateView);
	}

    updateView()
	{
		this.setState({ updateRevision: this.state.updateRevision + 1 })
	}

	closeDialog()
    {
        this.setState(
        {
            dialogShow_approveMoon: false,
            dialogShow_approvePegged: false
        })
    }

	getDialogRaw(_id)
	{
		const i = document.getElementById(_id)
		const v = i.value;
		return v;
	}

	getDialogTokensUint256(_id)
	{
		const v = LWeb3.tokensToUint256String(this.getDialogRaw(_id));
		const val = window.chef.web3_data.eth.abi.encodeParameter("uint256", v);
		
		return val;
	}
	
	getBuySellInputMax(_buy, _moon)
	{
		if (_buy)
		{
			return (_moon ? this.getTotalAvailableSupply() : window.chef.moonChef.peggedToken.userBalance)
		}
		else
		{
			return (_moon ? window.chef.moonChef.moonToken.userBalance : this.getBalanceUSD())
		}
	}

	getTotalAvailableSupply()
	{
		const aSup = window.chef.toBN(window.chef.moonChef.availableSupply)
		const mSup = window.chef.toBN(window.chef.moonChef.mintableSupply)
		const tSup = aSup.add(mSup)
		const total = tSup.toString(10)
		return total
	}

	getBalanceUSD()
	{
		return window.chef.moonChef.getBalanceUSD()
	}

	validateBuyAmounts()
	{
		const moonToBuy = LWeb3.tokensToUint256String(this.getDialogRaw('inputBuy_moon'));
		const moonToken = window.chef.moonChef.moonToken.symbol;

		if (parseFloat(moonToBuy) === 0)
		{
			ModalMessage.showModal("Cannot buy", `Please enter an amount of ${moonToken} to buy.`);
			return false;
		}

		const maxMoon = this.getBuySellInputMax(true, true);
		const availableMoon = LLib.formatDecimal(LWeb3.fullFormatTokens(maxMoon, window.chef.moonChef.moonToken, false));

		if (parseFloat(moonToBuy) > parseFloat(maxMoon))
		{
			ModalMessage.showModal("Cannot buy", `You are attempting to buy more ${moonToken} than the available supply. Please enter a value no larger than ${availableMoon}.`);
			return false;
		}

		const peggedToSpend = LWeb3.tokensToUint256String(this.getDialogRaw('inputBuy_pegged'));
		const maxPegged = this.getBuySellInputMax(true, false)
		const peggedToken = window.chef.moonChef.peggedToken.symbol;

		if (parseFloat(maxPegged) === 0)
		{
			ModalMessage.showModal("Cannot buy", `There is no ${peggedToken} in your wallet.  Please add some and try again.`);
			return false;
		}

		const availablePegged = LLib.formatDecimal(LWeb3.fullFormatTokens(maxPegged, window.chef.moonChef.peggedToken, false));

		if (parseFloat(peggedToSpend) > parseFloat(maxPegged))
		{
			ModalMessage.showModal("Cannot buy", `You are attempting to spend more ${peggedToken} than is available in your wallet. Please enter a value no larger than ${availablePegged} or add more ${peggedToken} to you wallet.`);
			return false;
		}

		return true;
	}

	validateSellAmounts()
	{
		const moonToSell = LWeb3.tokensToUint256String(this.getDialogRaw('inputSell_moon'));
		const moonToken = window.chef.moonChef.moonToken.symbol;

		if (parseFloat(moonToSell) === 0)
		{
			ModalMessage.showModal("Cannot sell", `Please enter an amount of ${moonToken} to sell.`);
			return false;
		}

		const maxMoon = this.getBuySellInputMax(false, true);
		const availableMoon = LLib.formatDecimal(LWeb3.fullFormatTokens(maxMoon, window.chef.moonChef.moonToken, false));

		if (parseFloat(moonToSell) > parseFloat(maxMoon))
		{
			ModalMessage.showModal("Cannot sell", `You are attempting to sell more ${moonToken} than is available in your wallet. Please enter a value no larger than ${availableMoon}.`);
			return false;
		}

		const peggedToReceive = LWeb3.tokensToUint256String(this.getDialogRaw('inputSell_pegged'));
		const peggedValueOfMoon = this.getBuySellInputMax(false, false)
		const availablePegged = LLib.formatDecimal(LWeb3.fullFormatTokens(peggedValueOfMoon, window.chef.moonChef.peggedToken, false));

		if (parseFloat(peggedToReceive) > parseFloat(peggedValueOfMoon))
		{
			const peggedToken = window.chef.moonChef.peggedToken.symbol;

			ModalMessage.showModal("Cannot sell", `You are attempting to receive more ${peggedToken} than your ${moonToken} is worth. Please enter a value no larger than ${availablePegged}.`);
			return false;
		}

		return true;
	}

	async onClick_buy()
	{
		let approved = await window.chef.moonChef.checkApprovedPegged()
		if (!approved)
		{
			//approve dialog
			this.setState({ dialogShow_approvePegged: true })
			return
		}

		if (!this.validateBuyAmounts())
		{
			return;
		}

		const amount = this.getDialogTokensUint256('inputBuy_moon')
		if (this.state.warnBeforeAction)
		{
			ModalNativeTokenBuySell.showModal(true, amount)
		}
		else
		{
			await window.chef.moonChef.buy(amount)		
		}
	}

	async onClick_sell()
	{
		let approved = await window.chef.moonChef.checkApprovedMoon()
		if (!approved)
		{
			//approve dialog
			this.setState({ dialogShow_approveMoon: true })
			return
		}

		if (!this.validateSellAmounts())
		{
			return;
		}

		const amount = this.getDialogTokensUint256('inputSell_moon')
		if (this.state.warnBeforeAction)
		{
			ModalNativeTokenBuySell.showModal(false, amount)
		}
		else
		{
			await window.chef.moonChef.sell(amount)		
		}
	}

	onClick_changeMode(_mode)
	{
		this.setState({ mode: _mode })
	}

	onValueChanged_buySellInput(_buy, _moon)
	{
		if (this.lock_buySellInput)
		{
			return
		}
		this.lock_buySellInput = true

		//get ids & elems
		const idPrefix = "input" + (_buy ? "Buy" : "Sell") + "_"
		const idCur = idPrefix + (_moon ? "moon" : "pegged")
		const idOther = idPrefix + (_moon ? "pegged" : "moon")
		const elemOther = document.getElementById(idOther)
		const otherMax = this.getBuySellInputMax(_buy, !_moon)

		//calc other value
		let converted
		const maxVal = window.chef.toBN(otherMax)
		const valCur = window.chef.toBN(this.getDialogTokensUint256(idCur))
		const valuePerMoon = window.chef.toBN(
			_buy ?
				window.chef.moonChef.tokenPrice :
				window.chef.moonChef.tokenValue)		
		if (valuePerMoon.cmp(window.chef.toBN(0)) === 0)
		{
			elemOther.value = 0
			return
		}
		if (_moon)
		{
			converted = valuePerMoon.mul(valCur).div(window.chef.moonChef.moonToken.one)
		}
		else
		{
			converted = valCur.mul(window.chef.moonChef.peggedToken.one).div(valuePerMoon)
		}
		if (converted.cmp(maxVal) === 1)
		{
			converted = maxVal
		}

		elemOther.value = LWeb3.fullFormatTokens(converted, this.state.token, true)
		this.lock_buySellInput = false
	}

	renderStat(_title, _value)
	{
		return (
			<Group className="row">
				<div className="col-6">
					<Text size="-1" color="2">
						{_title}
					</Text>
				</div>
				<div className="col-6">
					<Text size="-1" color="2">
						{LLib.renderLoading(window.chef.moonChef.initialized, _value)}
					</Text>
				</div>
			</Group>
		)
	}

	renderStats(_hide)
	{
		const vc = window.chef.vaultChef
		if (vc === null)
		{
			return
		}

		return (
			<Group className={"stats" + (_hide ? " hidden" : "")}>
				<Text color="1" size="0">
					Token Stats
				</Text>
				<Group className="StatsTables row">
					<div className="col-12 col-sm-6">
						{this.renderStat('Sell Price', LLib.smartFormatFloatDisplay(LWeb3.smartFormatTokens(window.chef.moonChef.tokenValue, window.chef.moonChef.peggedToken, 4), false, 2, 8))}
						{this.renderStat('Buy Price', LLib.smartFormatFloatDisplay(LWeb3.smartFormatTokens(window.chef.moonChef.tokenPrice, window.chef.moonChef.peggedToken, 4), false, 2, 8))}
						{this.renderStat('Buy Multiplier', LLib.smartFormatMultiplicator(window.chef.moonChef.buyPriceMultiplicator * 100, true))}
						{this.renderStat('Buy Fee', LLib.smartFormatMultiplicator(window.chef.moonChef.buyPriceFee * 100, true))}	
						{this.renderStat('Total Balance', LLib.smartFormatFloatDisplay(LWeb3.smartFormatFiat(window.chef.moonChef.totalBalance, window.chef.moonChef.peggedToken), false, 2, 2))}
					</div>
					<div className="col-12 col-sm-6">
						{this.renderStat('Redistributed Fee', LWeb3.formatFiatDisplay(window.chef.moonChef.wrappedBalanceUSD))}
						{this.renderStat('Circulating Supply', LLib.smartFormatFloatDisplay(LWeb3.smartFormatTokens(window.chef.moonChef.circulatingSupply, window.chef.moonChef.moonToken, true), false, 0, 4))}							
						{this.renderStat('Available Supply', LLib.smartFormatFloatDisplay(LWeb3.smartFormatTokens(this.getTotalAvailableSupply(), window.chef.moonChef.moonToken, true), false, 0, 4))}														
						{this.renderStat('Total Minted', LLib.smartFormatFloatDisplay(LWeb3.smartFormatTokens(window.chef.moonChef.totalMinted, window.chef.moonChef.moonToken, true), false, 0, 4))}	
						{this.renderStat('Emission per Day', LLib.smartFormatFloatDisplay(LWeb3.smartFormatTokens(window.chef.moonChef.emissionPerDay, window.chef.moonChef.moonToken, true), false, 0, 4))}
					</div>
				</Group>
			</Group>
		)
	}

	renderTokenIntro(_hide)
	{
		return (
			<Group className={"intro" + (_hide ? " hidden" : "")}>
				<Text color="1" size="2">
					MOON Token
				</Text>
				<Text color="2" size="-1">
					Click&nbsp;
					<Link size="-1" href="https://docs.moon-vault.com/products/token" target="blank">
						here
					</Link>
					&nbsp;to read the docs of our token.
					<br />
					Get it cheaper (0.5$ instead of 0.75$) at our <Link size="-1" href="/launchpad" nav={true}>Launchpad</Link>!
					<br />
					Stake MOON afterwards in our <Link size="-1" href="/pool" nav={true}>Pool</Link> to earn BUSD.
				</Text>
			</Group>
		)
	}

	renderBuy(_hide)
	{
		if (window.chef === null
			|| window.chef.moonChef === null)
		{
			return null
		}
		let buyPrice = LWeb3.smartFormatTokensDisplay(window.chef.moonChef.tokenPrice, window.chef.moonChef.peggedToken, 4) + " " + window.chef.moonChef.peggedToken.symbol
		return (
			<Group className={"buy" +  (_hide ? " hidden" : "")}>
				<ModalMoonApprove
					show={this.state.dialogShow_approvePegged}
					onClose={() => this.closeDialog()}
					moonToken="false"
					contract={window.chef.moonChef.address} />
				<InputTokenAmount
					id="inputBuy_pegged"
					token={window.chef.moonChef.peggedToken}
					max={this.getBuySellInputMax(true, false)}
					onChange={() => this.onValueChanged_buySellInput(true, false)} />
				<Group className="arrow">
					{LSymbols.arrow_down()}
				</Group>				
				<InputTokenAmountMoonVault
					id="inputBuy_moon"
					token={window.chef.moonChef.moonToken}
					max={this.getBuySellInputMax(true, true)}
					onChange={() => this.onValueChanged_buySellInput(true, true)}
					onChangeMoonChef={() => this.getTotalAvailableSupply()}
					balanceLabel="For Sale" />
				<Button className="orderButton" buttonStyle="1" onClick={() => this.onClick_buy()}>
					Buy MOON
					<br />
					{buyPrice} / MOON
				</Button>
			</Group>
		)
	}

	renderSell(_hide)
	{
		if (window.chef === null
			|| window.chef.moonChef === null)
		{
			return null
		}
		let sellPrice = LWeb3.smartFormatTokensDisplay(window.chef.moonChef.tokenValue, window.chef.moonChef.peggedToken, 4) + " " + window.chef.moonChef.peggedToken.symbol

		return (
			<Group className={"sell" + (_hide ? " hidden" : "")}>				
				<ModalMoonApprove
					show={this.state.dialogShow_approveMoon}
					onClose={() => this.closeDialog()}
					moonToken="true"
					contract={window.chef.moonChef.address} />
				<InputTokenAmount
					id="inputSell_moon"
					token={window.chef.moonChef.moonToken}
					max={this.getBuySellInputMax(false, true)}
					onChange={() => this.onValueChanged_buySellInput(false, true)} />
				<Group className="arrow">
					{LSymbols.arrow_down()}
				</Group>
				<InputTokenAmountMoonVault
					id="inputSell_pegged"
					token={window.chef.moonChef.peggedToken}
					max={this.getBuySellInputMax(false, false)}
					onChange={() => this.onValueChanged_buySellInput(false, false)}
					onChangeMoonChef={() => this.getBalanceUSD()}
					onChangeToken={() => this.getBalanceUSD()} />				
				<Button className="orderButton" buttonStyle="1" onClick={() => this.onClick_sell()}>
					Sell MOON
					<br />
					{sellPrice} / MOON
				</Button>
			</Group>
		)
	}

	renderToken()
	{
		return (
			<Group className="token">		
				<ModalNativeTokenBuySell />		
				{this.renderTokenIntro()}		
				<Group className="menu">
					<Button
						className="col-3 BuyMoon"
						buttonStyle={this.state.mode === "buy" ? 1 : 0}
						onClick={() => this.onClick_changeMode("buy")}>
							Buy MOON
					</Button>
					<Button
						className="col-3 SellMoon"
						buttonStyle={this.state.mode === "sell" ? 1 : 0}
						onClick={() => this.onClick_changeMode("sell")}>
							Sell MOON
					</Button>
					<Button
						className="col-3"
						buttonStyle={this.state.mode === "charts" ? 1 : 0}
						onClick={() => this.onClick_changeMode("charts")}>
							Charts
					</Button>
				</Group>
				{this.renderBuy(this.state.mode !== "buy")}
				{this.renderSell(this.state.mode !== "sell")}
				{this.renderCharts(this.state.mode !== "charts")}
				{this.renderStats(this.state.mode !== "buy" && this.state.mode !== "sell")}
			</Group>
		)
	}

	renderCharts()
	{
		return (
			<Group className={"charts" + (this.state.mode === "charts" ? "" : " hidden")}>
				<Group className="header">
					<Text
						className="underlined"
						color="1"
						size="1">
						Value per MOON:
					</Text>
				</Group>
				<ChartNativePrice
					height="150"
					useColor="0" />

				<Group className="header">
					<Text
						className="underlined"
						color="1"
						size="1">
						Pegged Balance:
					</Text>
				</Group>
				<ChartNativePeggedBalance
					height="150"
					useColor="1" />
					
				<Group className="header">
					<Text
						className="underlined"
						color="1"
						size="1">
						Circulating Supply:
					</Text>
				</Group>
				<ChartNativeCirculatingSupply
					height="150"
					useColor="2" />
			</Group>
		)
	}

	renderPanelCharts()
	{
		return (
			<Panel className="Panel_Charts col-12">
				{this.renderCharts()}
			</Panel>
		)
	}

	renderPanelToken()
	{
		return (
			<Panel className="Panel_Token col-sm-12 col-md-6">
				{this.renderToken()}
			</Panel>
		)
	}

	renderPanelStats()
	{
		return (
			<Panel className="Panel_Stats col-sm-12 col-md-6">
				{this.renderStats()}					
			</Panel>
		)
	}

	renderPanelWrongChain()
	{
		let chain = window.chef.findChain(window.chef.moonChef?.chainID)

		return (
			<Group className="PageToken">
				<Panel className="Panel_Token">
					{this.renderTokenIntro()}
					<Group className="wrongChain">
						<Text color="2">
							Our token is deployed on&nbsp;					
						</Text>
						<Link onClick={() => LWeb3.switchChain(chain.id)}>
							{chain?.name}
						</Link>
						<Text color="2">
							.
							<br />
							Switch chain to interact or get more info.
						</Text>
					</Group>
				</Panel>
			</Group>
		)
	}

    render()
    {
		if (!window.chef.moonChef?.isValidChain())
		{
			return this.renderPanelWrongChain()
		}

        return (
			<Group className="PageToken">
				{this.renderPanelToken()}
			</Group>
		)
    }
}

export default PageToken;