import React from 'react';

//components
import { Group, Button, Panel, Text } from '../../Controls'
import { ButtonSwapLiquidity } from '../../Controls/AddOn'

//modals
import ModalConfirm from '../../Modals/ModalConfirm/ModalConfirm'

//css
import './PageAdmin.css'

class PageAdmin extends React.Component
{
	constructor(props)
	{   
		super(props)

        //init state
		this.state = 
		{
            updateRevision: 0
		}

		this.updateView = this.updateView.bind(this)
	}

	componentDidMount()
	{	
        document.addEventListener('user_userInfo', this.updateView)   
	}

	componentWillUnmount()
	{
		this.processEnabled = false
		document.removeEventListener('user_userInfo', this.updateView)   
	}    

    updateView()
	{
		this.setState({ updateRevision: this.state.updateRevision + 1 })
	}

	addAdminLog(_text)
	{
		let log = document.getElementById("adminLog")
		if (!!log)
		{
			log.innerHTML += _text + "\n"		
			if (document.activeElement !== log)
			{
				log.scrollTop = log.scrollHeight;
			}
		}
	}
	
	async onClick_resetChain()
	{
		ModalConfirm.showModal(
			"Reset Chain?",
			"Do you really want to reset the chain?",
			() =>
			{
				try
				{
					fetch(window.chef.api_url + "?module=admin&action=resetChain&chain=" + window.chef.currentChain.id)
					this.addAdminLog("[SYNC] reset")	
				}
				catch (e) { }	
			})			
	}
	
	async onClick_resetChainProcessing()
	{
		ModalConfirm.showModal(
			"Reset Processing?",
			"Do you really want to reset the processing?",
			() =>
			{
				try
				{
					fetch(window.chef.api_url + "?module=admin&action=resetChainProcessing&chain=" + window.chef.currentChain.id)
					this.addAdminLog("[PROCESS] reset")		
				}
				catch (e) { }	
			})	
	}

	async onClick_generateData()
	{
		try
		{
			await fetch(window.chef.api_url + "?module=admin&action=generate")
			this.addAdminLog("[GENERATE] data")	
		}
		catch (e) { }		
	}

	async onClick_applyGenerated()
	{
		ModalConfirm.showModal(
			"Apply generated?",
			"Do you really want to apply generated data?",
			async () =>
			{
				try
				{
					await fetch(window.chef.api_url + "?module=admin&action=applyGenerated")
					this.addAdminLog("[GENERATE] applied")		
				}
				catch (e) { }	
			})	
	}

	async onClick_refillPool()
	{
		ModalConfirm.showModal(
			"Refill Pool?",
			"Do you really want to refill & prolong pool for 7 days?",
			async () =>
			{
				await window.chef.moonPool.refill(604800);
			})	
	}

	async onClick_logout()
	{
		await window.chef.logout()
	}
	
	renderLogout()
	{
		return (
			<Group className="Logout">
				<Button buttonStyle="1" onClick={() => this.onClick_logout()}>
					logout
				</Button>
			</Group>
		)
	}

	renderAdminActions()
	{
		return (
			<Group className="AdminCenter">
				<Group className="SyncProcessControl">
					<Group className="SyncProcessPanels">						
						<Group className="SyncProcessPanel">						
							<Button buttonStyle="1" onClick={() => this.onClick_resetChain()}>
								reset chain
							</Button>						
							<Button buttonStyle="1" onClick={() => this.onClick_resetChainProcessing()}>
								reset processing
							</Button>
							<Button buttonStyle="1" onClick={() => this.onClick_generateData()}>
								generate data
							</Button>
							<Button buttonStyle="1" onClick={() => this.onClick_applyGenerated()}>
								apply generated
							</Button>
						</Group>
					</Group>
				</Group>
			</Group>
		)
	}

	renderAdminMoonActions()
	{
		return (
			<Group className="AdminCenter">
				<Group className="SyncProcessControl">
					<Group className="SyncProcessPanels">						
						<Group className="SyncProcessPanel">						
							<ButtonSwapLiquidity token={window.chef.wrappedCoin} />
							<Button buttonStyle="1" onClick={() => this.onClick_refillPool()}>
								refill pool 7
							</Button>
							<ButtonSwapLiquidity token={window.chef.wrappedCoin} pool={true} />
						</Group>
					</Group>
				</Group>
			</Group>
		)
	}

	renderAdminCenter()
	{
		return (
			<Group className="AdminCenter">
				<Text size="2" className="header">
					Admin Center
				</Text>
				{this.renderLogout()}
				<Group className="SyncProcessControl">				
					<textarea readOnly="readonly" id="adminLog" />
				</Group>
			</Group>
		)
	}

    render()
    {
		//check login
		let login = window.chef.template.checkLogin()
		if (login !== true)
		{
			return (
				<Group className="Page_Admin">
					{login}
				</Group>				
			)
		}
		
		//show content
		return (
			<Group className="Page_Admin">
				<Panel className="Panel_Admin">
					{this.renderAdminCenter()}
				</Panel>
				<Panel className="Panel_Actions">
					{this.renderAdminActions()}
				</Panel>
				{window.chef.moonChef?.isValidChain() &&
					<>
						<Panel className="Panel_Actions">
							{this.renderAdminMoonActions()}
						</Panel>
					</>
				}
			</Group>
		)
    }
}

export default PageAdmin;