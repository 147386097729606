//libs
import {LLib} from '../../../../../../libs/LLib';

//components
import { Chart } from '../../../../';

class ChartVaultApr extends Chart
{
	constructor(props)
	{   
		super(props)

		//init state
		this.state =
		{
			...this.state,
			vaultID: props.vaultID,
			days: props.days || 180
		}
	}
	
	componentDidMount()
	{
		this.reloadData()
		this.interval = setInterval(() => this.reloadData(), 60000)
		this.setState({ options: this.initChart() })
	}
	
	componentWillUnmount()
	{			
		clearInterval(this.interval)
	}
	
	async reloadData()	
	{
		const vault = window.chef.findVault(this.state.vaultID)

		//compounds		
		const compounds = await vault.db_getHistoricCompounds(
		{
			days: this.state.days
		})
		
		//fill data
		this.processData(compounds)
	}
	
	initChart(_compounds)
	{		
		//init
		let colors = this.makeColorList()
		let rangeStart = new Date()
		rangeStart.setDate(rangeStart.getDate() - this.state.days);
		
		//set options
		let opts = this.makeOptions()	
		this.setLabels()
		this.setType(opts, "area")
		this.setColors(opts, colors)
		opts.chart = 
		{
			...opts.chart,
			sparkline:
			{
				enabled: !this.showLabels
			},
			zoom:
			{
				type: 'x'
			}
		}
		opts.xaxis =
		{
			...opts.xaxis,
			type: "datetime",
			//min: rangeStart.getTime(),
			labels:
			{
				...opts.xaxis.labels,
				show: false
			},
			axisTicks:
			{
				show: false
			},
			tooltip:
			{
				enabled: false
			}
		}
		opts.yaxis =
		{
			...opts.yaxis,
			show: this.showLabels,
			tickAmount: 4,
			labels:
			{
				formatter: (_val) =>
				{
					return LLib.smartFormatPercent(_val, true, 4, 4);
                }
			}
		}
		opts.tooltip = 
		{
			...opts.tooltip,
			x:
			{
				...opts.tooltip.x,
				show: true,
				formatter: function(value)
                {
					return LLib.formatDate(value, true, true);
				}
			}
		}
		opts.legend =
		{
			...opts.legend,
			show: true,
			position: 'top'
		}
		opts.fill = 
		{
			...opts.fill,
			type: 'gradient',
            gradient:
			{				
				type: 'vertical',
				opacityFrom: 0.8,
				opacityTo: 0.3,
				stops: [0, 100]
			}
		}
		return opts	
	}
	
	async processData(_compounds)
	{
		if (_compounds === undefined)
		{
			return
		}
		
		let series = []
		let categories = []															 
		let data = []
		let cats = []
		for (let n = 0; n < _compounds.data.length; n++)
		{
			let comp = _compounds.data[n]
			let dt = LLib.getDateTimeFromDB(comp.dateTime)
			let dtVal = dt.getTime()
			cats.push(dtVal)			
			
			let val = parseFloat(comp.dailyAPR).toFixed(4)
			data.push([dtVal, val])
		}
		series.push(
		{
			name: "Daily ROI",
			data: data					
		})
		categories = cats
		
		let opts = this.initChart(_compounds)
		this.setSeries(opts, series, categories)
	}
}

export default ChartVaultApr;