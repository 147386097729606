import React from 'react';

//libs
import {LLib, LWeb3, LSymbols} from '../../../libs/'

//components
import { Group, Button, Panel, Text, Input, Link, HelpIcon } from '../../Controls'
import { ButtonLoginLogout } from '../../Controls/AddOn';

//css
import './PageUser.css'

class PageUser extends React.Component
{
	constructor(props)
	{   
		super(props)

        //init state
		this.state = 
		{
            updateRevision: 0
		}

		this.updateView = this.updateView.bind(this)
	}

    componentDidMount()
	{	
        document.addEventListener('user_userInfo', this.updateView)   
		document.addEventListener('app_reload', this.updateView)   
	}

	componentWillUnmount()
	{	
        document.removeEventListener('user_userInfo', this.updateView)   
		document.removeEventListener('app_reload', this.updateView)   
	}

    updateView()
	{
		this.setState({ updateRevision: this.state.updateRevision + 1 })
	}

	async onClick_setReferrer()
	{
		const referrer = document.getElementById("referrer").value
		await window.chef.vaultChef.setReferrer(referrer)
	}

	async onClick_changePassword()
	{
		let form = document.getElementById("formChangePassword")        
        if (form['new'].value === form['new2'].value)
        {
		    await LLib.fetchJSONFromForm(window.chef.api_url + "?module=user&action=changePassword", new FormData(form))
			await window.chef.refreshUserData()
        }
	}
	
	renderChangePassword()
	{
		return (
			<Group className="ChangePassword">
				<form id="formChangePassword" onSubmit={(_eventArgs) => _eventArgs.preventDefault()}>
                    <Group className="password">
						<Text>
							Password (old):						
						</Text>
						<Input name="old" type="password" />
					</Group>
                    <Group className="password">
						<Text>
							Password (new):						
						</Text>
						<Input name="new" type="password" />
					</Group>
                    <Group className="password">
						<Text>
							Password (new confirmation):						
						</Text>
						<Input name="new2" type="password" />
					</Group>
					<Button buttonStyle="1" onClick={() => this.onClick_changePassword()}>
						change password
					</Button>
				</form>
			</Group>
		)
	}

	renderInfoRow(_name, _value, _additionalClass)
	{
		let className = "infoRow";
		if (_additionalClass) 
		{
			className += " " + _additionalClass;
		}

		return (
			<Group className={className}>
				<Group className="name">
					<Text color="2" size="0">
						{_name}
					</Text>
				</Group>
				<Group className="value">
					{_value}
				</Group>
			</Group>
		)
	}

	renderPanelInfo()
	{
		const urlParams = new URLSearchParams(window.location.search)
		const urlParamReferrer = (urlParams.get('referral') || "")
		let referrer = window.chef.vaultChef?.referredBy || "0"
		let referalLink = ""
		if (window.chef.account !== null)
		{
			referalLink = window.location.protocol + '//' + window.location.host + window.location.pathname + "?referral=" + window.chef.account
		}
		if (referrer === undefined
			|| referrer === "")
		{
			referrer = urlParamReferrer		
		}
		if (window.chef.toBN(referrer).cmp(window.chef.toBN(0)) === 0)
		{
			referrer = ""
		}

		return (
			<Panel className="Panel_Info">
				<Group className="header">
					<Text size="1" color="1">
						Info
					</Text>
				</Group>
				<Group className="infos">
					{this.renderInfoRow("Wallet:", (
						<>
							<Group>
								{window.chef.account}
							</Group>
							<ButtonLoginLogout />
						</>
					), "Wallet")}

					{this.renderInfoRow("Referral Link:", (
						<>							
							<Input id="referralLink" readOnly={true} value={referalLink} />	
							<Button buttonStyle="1" onClick={() => navigator.clipboard.writeText(document.getElementById("referralLink").value)}>
								{LSymbols.clipboard("svgLinkBackground")}
							</Button>
						</>
					))}

					{this.renderInfoRow("Referred By:", (
						<>
							<Input id="referrer" value={referrer} />					
							<Button buttonStyle="1" onClick={() => this.onClick_setReferrer()}>
								{LSymbols.checkCircle("svgLinkBackground")}
							</Button>
						</>
					))}
				</Group>
			</Panel>
		)
	}

	renderStat(_title, _value, _loaded, _highlighted)
	{
		let val = <>&nbsp;</>
		if (_title !== '')
		{
			val = LLib.renderLoading(_loaded || _title === '', _value)
		}

		return (
			<tr>
				<td className="name col-6">
					<Text size="0" color="2">
						{_title}
					</Text>
				</td>
				<td className="value col-6">
					<Text size="0" color={_highlighted ? 1 : 2}>
						{val}
					</Text>
				</td>
			</tr>
		)
	}

	renderVotingPower()
	{
		const vp = window.chef.userVotingBreakdown;
		const unit = 1000000;
		if (!vp)
		{
			return <></>;
		}

		//get compound power
		let compoundPower = 0;
		if (vp.chains)
		{			
			vp.chains.forEach(c => compoundPower = Math.max(compoundPower, c.compounds));
		}

		//get event power
		let eventPower = 0;
		if (vp.events)
		{			
			vp.events.forEach(e => eventPower += e.value);
		}

		return (
			<HelpIcon placement="left" mobilePlacement="bottom-start">
				<Group className="VotingPowerBreakdown">
					<Group className="Description">
						Voting power depends on many different factors. See our <Link href="https://docs.moon-vault.com/protocol/governance" target="_blank">docs</Link> for more info.
					</Group>
					{vp && vp.firstDeposit &&
						<Group>
							<Group>Membership</Group>
							<Group>{LLib.smartFormatFloatDisplay(vp.firstDeposit / unit, false, 2)}</Group>
						</Group>
					}
					<Group>
						<Group>Compounding</Group>
						<Group>{LLib.smartFormatFloatDisplay(compoundPower / unit, false, 2)}</Group>
					</Group>		
					<Group>
						<Group>Staked MOON</Group>
						<Group>{LLib.smartFormatFloatDisplay(vp.moon / unit, false, 2)}</Group>
					</Group>
					<Group>
						<Group>Events</Group>
						<Group>{LLib.smartFormatFloatDisplay(eventPower / unit, false, 2)}</Group>
					</Group>
					<Group className="TotalPower">
						<Group>Total Voting Power</Group>
						<Group>{LLib.smartFormatFloatDisplay(window.chef.userVotingPower, false, 2)}</Group>
					</Group>
				</Group>
			</HelpIcon>
		);
	}

	renderStats()
	{
		const userDataLoaded = (
			window.chef.account !== null			
	 			&& window.chef.refreshCount >= 1)

		//native token
		let nativeInfo = <></>
		if (window.chef.moonChef.isValidChain())
		{
			const moonBalance = window.chef.toBN(window.chef.moonChef.moonToken.userBalance);
			const moonStaked = window.chef.toBN(window.chef.moonPool.userDeposit);
			const moonOwned = moonBalance.add(moonStaked).toString(10);
			const moonOwnedUSD = window.chef.moonChef.getPriceUSDForAmount(moonOwned);
			const moonPoolPendingUSD = window.chef.toBN(window.chef.moonPool.userPendingUSD);
			const moonPoolClaimedUSD = window.chef.toBN(window.chef.moonPool.userClaimedUSD);
			const moonEarnedUSD = moonPoolPendingUSD.add(moonPoolClaimedUSD);
			nativeInfo = (
				<>
					{this.renderStat('', '')}
					{this.renderStat(`${window.chef.moonChef.moonToken.symbol} Balance:`, LWeb3.smartFormatTokensDisplay(moonBalance, window.chef.moonChef.moonToken, true), userDataLoaded)}
					{this.renderStat(`${window.chef.moonChef.moonToken.symbol} Staked:`, LWeb3.smartFormatTokensDisplay(moonStaked, window.chef.moonChef.moonToken, true), userDataLoaded)}
					{this.renderStat(`${window.chef.moonChef.moonToken.symbol} Total $:`, LWeb3.formatFiatDisplay(moonOwnedUSD), userDataLoaded)}
					{this.renderStat(`${window.chef.moonPool.rewardToken.symbol} Earned $:`, LWeb3.formatFiatDisplay(moonEarnedUSD), userDataLoaded)}
				</>
			)
		}

		return (
			<Panel className="Panel_Stats">
				<Group className="stats">
					<Text className="header" color="1" size="1">
						User Stats
					</Text>
					<Group className="StatsTables col-12">
						<table className="StatsTable col-12">
							<tbody>
								{this.renderStat('Total Deposit:', LWeb3.formatFiatDisplay(window.chef.userDepositUSD), userDataLoaded)}
								{this.renderStat('Total Pending:', LWeb3.formatFiatDisplay(window.chef.userPendingUSD), userDataLoaded)}
								{this.renderStat(
									'Voting Power:',
									<Group className="votingPower">
										{LLib.smartFormatFloatDisplay(window.chef.userVotingPower, false, 2)}
										{this.renderVotingPower()}
									</Group>,
									window.chef.userVotingPower !== 0)}

								{nativeInfo}

								{this.renderStat('', '')}
								{this.renderStat('Average daily APR:', LLib.smartFormatPercent(window.chef.avgDailyAPR * 100, true), userDataLoaded)}
								{this.renderStat('Average APR:', LLib.smartFormatPercent(window.chef.avgYearlyAPR * 100, true), userDataLoaded)}
								{this.renderStat('Average APY:', LLib.smartFormatPercent(window.chef.avgYearlyAPY * 100, true), userDataLoaded)}

								{this.renderStat('', '')}
								{this.renderStat('Your Earnings:', "(without compound)", true, 1)}
								{this.renderStat('- Daily:', LLib.formatFiat(window.chef.roiDailyAPR.toFixed(2)), userDataLoaded)}
								{this.renderStat('- Weekly:', LLib.formatFiat(window.chef.roiWeeklyAPR.toFixed(2)), userDataLoaded)}
								{this.renderStat('- Monthly:', LLib.formatFiat(window.chef.roiMonthlyAPR.toFixed(2)), userDataLoaded)}
								{this.renderStat('- Yearly:', LLib.formatFiat(window.chef.roiYearlyAPR.toFixed(2)), userDataLoaded)}

								{this.renderStat('', '')}
								{this.renderStat('Your Earnings:', "(compounded)", true, 1)}
								{this.renderStat('- Daily:', LLib.formatFiat(window.chef.roiDailyAPY.toFixed(2)), userDataLoaded)}
								{this.renderStat('- Weekly:', LLib.formatFiat(window.chef.roiWeeklyAPY.toFixed(2)), userDataLoaded)}
								{this.renderStat('- Monthly:', LLib.formatFiat(window.chef.roiMonthlyAPY.toFixed(2)), userDataLoaded)}
								{this.renderStat('- Yearly:', LLib.formatFiat(window.chef.roiYearlyAPY.toFixed(2)), userDataLoaded)}

							</tbody>
						</table>
					</Group>
				</Group>
			</Panel>
		)
	}

    render()
    {		
		const vc = window.chef.vaultChef
		if (vc === null
			|| window.chef.account === null)
		{
			return (
				<Group className="Page_User">
					<Panel className="Panel_Stats">
						<Group className="noWallet">
							<Text color="2">
								Connect your wallet to see user stats!
							</Text>
						</Group>
					</Panel>				
				</Group>
			)
		}

		return (
			<Group className="Page_User">
				{this.renderStats()}
				{this.renderPanelInfo()}				
			</Group>
		)
    }
}

export default PageUser;