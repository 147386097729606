import React from 'react';

//libs
import {LLib, LWeb3, LSymbols} from '../../../libs/'

//config
import {config} from '../../../config'

//components
import { Text, Group, Panel, Link, Image, HelpIcon } from '../../Controls'
import { ChartProtocolTvl, MissingData, ChartNativePrice } from '../../Controls/AddOn'

//css
import './PageHome.css'

class PageHome extends React.Component
{
	constructor(props)
	{   
		super(props)
		 
		//init state
		this.state = 
		{
			updateRevision: 0
		}

		this.updatePage = this.updatePage.bind(this)
	}

	componentDidMount()
	{	
		this.updatePage()
		document.addEventListener('chef_dataLoaded', this.updatePage)
		document.addEventListener('app_reload', this.updatePage)       
	}

	componentWillUnmount()
	{	
		document.removeEventListener('chef_dataLoaded', this.updatePage)
		document.removeEventListener('app_reload', this.updatePage)       
	}

	updatePage()
	{
		this.setState({ updateRevision: this.state.updateRevision + 1 })
	}

	renderContactMedia()
	{
		return (			
			<Group className="links glow">
				<Group className="LinkGroup">					
					<Link color="2" href="https://t.me/Moon_Vault" target="blank">
						{LSymbols.telegram("svgLink tg")}
						Telegram
					</Link>
					<Link color="2" href="https://t.me/Moon_Vault_News" target="blank">
						{LSymbols.telegram("svgLink tg")}
						News
					</Link>
					<Link color="2" href="https://twitter.com/Moon_Vault_News" target="blank">
						{LSymbols.twitter("svgLink twitter")}
						Twitter
					</Link>
				</Group>
				<Group className="LinkGroup">
					<Link color="2" href="https://docs.moon-vault.com" target="blank">
						{LSymbols.gitbook("svgLink")}
						Docs
					</Link>
					<Link color="2" href="https://docs.moon-vault.com/roadmap/roadmap" target="blank">
						{LSymbols.roadmap("svgLink")}
						Roadmap
					</Link>
					<Link color="2" href="https://docs.moon-vault.com/team-and-wallets" target="blank">
						{LSymbols.team("svgLink")}
						Team
					</Link>
				</Group>
			</Group>
		)
	}

	renderStat(_title, _value, _leftColSpan = 7, _color = "2")
	{
		const left = `name col-${_leftColSpan}`;
		const right = `value col-${(12 - _leftColSpan)}`;

		return (
			<Group className="row">
				<Group className={left}>
					<Text size="-1" color={_color}>
						{_title}
					</Text>
				</Group>
				<Group className={right}>
					<Text size="-1" color={_color}>
						{_value}
					</Text>
				</Group>
			</Group>
		)
	}

	renderStats()
	{
		const vc = window.chef.vaultChef
		if (vc === null)
		{
			return
		}

		return (
			<Group className="stats">
				<Text color="1" size="0">
					Protocol Stats ({window.chef.currentChain?.name})
				</Text>
				<Group className="row">
					<Group className="col-12 col-sm-6">
						{this.renderStat('Total Value Locked:', LLib.renderLoading(window.chef.refreshCount > 0, LWeb3.formatFiatDisplay(window.chef.totalDepositUSD) ))}						
						{this.renderStat('Min Daily ROI:', LLib.renderLoading(window.chef.refreshCount > 0, LLib.smartFormatPercent(window.chef.minAPR * 100, true)))}
						{this.renderStat('Max Daily ROI:', LLib.renderLoading(window.chef.refreshCount > 0, LLib.smartFormatPercent(window.chef.maxAPR * 100, true)))}
						{window.chef.vaultChef.check_isTeam() &&
							<>
								{this.renderStat('Average Daily ROI:', LLib.renderLoading(window.chef.refreshCount > 0, LLib.smartFormatPercent(window.chef.totalDailyROI * 100, true)), 7, "1")}
								{this.renderStat('Total Pending:', LLib.renderLoading(window.chef.refreshCount > 0, LWeb3.formatFiatDisplay(window.chef.totalPendingUSD)), 7, "1")}						
							</>
						}
					</Group>
					<Group className="col-12 col-sm-6">
						{this.renderStat('Vaults:', window.chef.vaults.length)}
						{this.renderStat('Profit Fee (Total):',
							<>
								{LLib.smartFormatPercent(vc.totalProfitFee * 100, true)}
								<HelpIcon placement="left" mobilePlacement="bottom-start">
									<Group className="ProfitFeeBreakdown">
										<Group className="Description">
											The profit fee is applied only to a vault when it is compounded. See our <Link href="https://docs.moon-vault.com/products/vaults/fees" target="_blank">docs</Link> for more info.
										</Group>
										<Group>
											<Group>MOON Token Fee</Group>
											<Group>{LLib.smartFormatPercent(vc.nativeLiquidityFee * 100, true)}</Group>
										</Group>
										<Group>
											<Group>MOON Pool Fee</Group>
											<Group>{LLib.smartFormatPercent(vc.nativePoolFee * 100, true)}</Group>
										</Group>
										<Group>
											<Group>Compounding Reward Fee</Group>
											<Group>{LLib.smartFormatPercent(vc.compoundRewardFee * 100, true)}</Group>
										</Group>
										<Group className="TotalFee">
											<Group>Total Profit Fee</Group>
											<Group>{LLib.smartFormatPercent(vc.totalProfitFee * 100, true)}</Group>
										</Group>
									</Group>
								</HelpIcon>
							</>,
							8, 2)}
						{this.renderStat('Withdrawal Fee:', LLib.smartFormatPercent(vc.withdrawFee * 100, true), 8)}
						{window.chef.vaultChef.check_isTeam() &&
							<>
								{this.renderStat('~ Daily User Profit:', LLib.renderLoading(window.chef.refreshCount > 0, LWeb3.formatFiatDisplay(window.chef.totalDailyUSD)), 7, "1")}
								{this.renderStat('~ Daily Protocol Profit:', LLib.renderLoading(window.chef.refreshCount > 0, LWeb3.formatFiatDisplay(window.chef.totalDailyProtocolUSD)), 7, "1")}
							</>
						}
					</Group>
				</Group>
			</Group>
		)
	}

	renderPanelCharts()
	{
		return (		
			<Panel className="Panel_Charts">
				<Group className="charts">
					<Group className="header">
						<Text
							className="underlined"
							color="1"
							size="1">
							Protocol TVL:
						</Text>
					</Group>
					<ChartProtocolTvl
						missingDataStart={MissingData.DefaultToZero}
						missingDataMiddle={MissingData.FillIn}
						missingDataEnd={MissingData.FillIn}
						showLabels={false}
						width="100%"
						height="200" />

					<Group className="header">
						<Text
							className="underlined"
							color="1"
							size="1">
							{window.chef.moonChef?.moonToken?.symbol} Value:
						</Text>
					</Group>
					<ChartNativePrice
						width="100%"
						height="200"
						useColor={0} />
				</Group>				
			</Panel>
		)
	}

    render()
    {
        return (
			<Group className="PageHome">
				<Panel className="Panel_Info">
					<Group className="header">
						<Text size="2">
							{config.page.name}
						</Text>
					</Group>
					{this.renderContactMedia()}											
					<Image className="logo" src="/logo.gif" />
					{this.renderStats()}					
				</Panel>
				{this.renderPanelCharts()}
			</Group>
		)
    }
}

export default PageHome;