import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom'

//components
import { PageHome, PageVaults, PageToken, PagePool, PageAdmin, PageUser, PageVaultDetails, PageOnRamp, PageLaunchpad } from '../Pages'

class PageManager extends React.Component
{
	static pages =
	[
		{
			id: "home",
			label: "Home"
		},
		{
			id: "vaults",
			label: "Vaults"
		},
		{
			id: "user",
			label: "User"
		}
	]

	static nativeChainPages = 
	[
		{
			id: "token",
			label: "Token"
		},
		{
			id: "pool",
			label: "Pool"
		}
	]

	static loggedInPages = 
	[
		{
			id: "admin",
			label: "Admin"
		}
	]

	componentDidUpdate(prevProps)
	{
		if (prevProps.location.pathname !== this.props.location.pathname) 
		{
			this.props?.routeChanged(this.props.location.pathname);
		}
	}

    render()
    {
		return (
			<Switch>
				<Route path="/" exact>
					<PageHome />
				</Route>
				<Route path="/home" exact>
					<PageHome />
				</Route>
				<Route exact path="/vaults">
					<PageVaults />
				</Route>
				<Route exact path="/token">
					<PageToken />
				</Route>
				<Route exact path="/pool">
					<PagePool />
				</Route>
				<Route exact path="/admin">
					<PageAdmin />
				</Route>
				<Route exact path="/user">
					<PageUser />
				</Route>
				<Route exact path="/fiat">
					<PageOnRamp />
				</Route>
				<Route exact path="/launchpad">
					<PageLaunchpad />
				</Route>
				<Route path="/vault">
					<PageVaultDetails />
				</Route>
			</Switch>
		)
    }
}

export default withRouter(PageManager);