import React from 'react';

//libs
import { LLib, LWeb3 } from '../../../libs/'

//components
import { Text, Group, Panel, Button, Link, InputTokenAmount, ProgressBar } from '../../Controls'

//modals
import ModalConfirm from '../../Modals/ModalConfirm/ModalConfirm'
import ModalMessage from '../../Modals/ModalMessage/ModalMessage'

//css
import './PageLaunchpad.css'

class PageLaunchpad extends React.Component
{
	constructor(props)
	{   
		super(props)
		 
		//init state
		this.state = 
		{
			updateRevision: 0,
			dialogShow_approveMoon: false,
			dialogShow_approvePegged: false,
			mode: "buy",
			warnBeforeAction: true
		}

		this.refInputDeposit = React.createRef();
		this.updateView = this.updateView.bind(this);
	}

	componentDidMount()
	{	
		LLib.subscribeEvents(
			[
				'chef_dataLoaded',
				'launchpad_Info',
				'launchpad_userInfo'
			],
			this.updateView);
	}

	componentWillUnmount()
	{	
		LLib.unsubscribeEvents(
			[
				'chef_dataLoaded',
				'launchpad_Info',
				'launchpad_userInfo'
			],
			this.updateView);
	}

    updateView()
	{
		this.setState({ updateRevision: this.state.updateRevision + 1 })
	}

	checkAlreadyClaimed()
	{
		if (window.chef.launchpad.userClaimedSoft
			|| window.chef.launchpad.userClaimedHard)
		{
			ModalMessage.showModal(
				"Can't claim",
				"You already claimed your rewards.");
			return true;
		}
		return false;
	}

	async onClick_claimSoft()
	{
		if (this.checkAlreadyClaimed())
		{
			return;
		}
		ModalConfirm.showModal(
			"Claim Soft Cap?",
			"Are you sure you want to claim soft cap and withdraw the remaining funds?",
			async () => await window.chef.launchpad.claimSoftCap());
	}

	async onClick_claimHard()
	{
		if (this.checkAlreadyClaimed())
		{
			return;
		}
		ModalConfirm.showModal(
			"Claim Hard Cap?",
			"Are you sure you want to claim hard cap and withdraw the remaining funds?",
			async () => await window.chef.launchpad.claimHardCap());
	}

	async onClick_claimDuringActive()
	{
		ModalMessage.showModal(
			"Can't claim",
			"The Launchpad is still running. You can claim after it has ended.");
	}

	async onClick_deposit()
	{
		if (!window.chef.launchpad.approved)
		{
			await window.chef.launchpad.approve();
			return;
		}

		const val = this.refInputDeposit.current.getAsUint256();
		await window.chef.launchpad.deposit(val);
	}

	async onClick_depositRewards()
	{
		if (!window.chef.launchpad.approvedReward)
		{
			await window.chef.launchpad.approveReward();
			return;
		}
		await window.chef.launchpad.depositRewards();
	}

	renderPanelWrongChain()
	{
		let chain = window.chef.findChain(window.chef.moonChef?.chainID)

		return (
			<Group className="PageToken">
				<Panel className="Panel_Launchpad">
					<Group className="wrongChain">
						<Text color="2">
							Our Launchpad is deployed on&nbsp;					
						</Text>
						<Link onClick={() => LWeb3.switchChain(window.chef.moonChef?.chainID)}>
							{chain?.name}
						</Link>
						<Text color="2">
							.
							<br />
							Switch chain to interact or get more info.
						</Text>
					</Group>
				</Panel>
			</Group>
		)
	}

	renderDepositOrWithdraw()
	{
		const lp = window.chef.launchpad;

		//check phase
		const now = (new Date()).getTime() / 1000;
		const start = lp.startTime.getTime() / 1000;
		const end = start + lp.duration;

		//deposit rewards
		let admin = <></>
		if (lp.missingRewards !== "0"
			&& lp.totalDeposit === "0"
			&& LWeb3.compareAddress(lp.owner, window.chef.account || "") === 0)
		{
			admin = (
				<Group className="deposit">
					<Text size="1" className="head">
						Deposit Rewards:
					</Text>
					<Text color="2">
						Missing Rewards: {LWeb3.smartFormatTokensDisplay(lp.missingRewards, lp.rewardToken, true)} {lp.rewardToken.symbol}
					</Text>
					<Button buttonStyle="1" onClick={() => this.onClick_depositRewards()}>
						{lp.approvedReward ? "Deposit" : "Enable"}
					</Button>
				</Group>		
			);	
		}

		if (now < start)
		{
			//not started
			return (
				<>
					{admin}
					<Group className="launchpadNotStarted">
						<Text size="1">
							Launchpad starts at {LLib.formatDate(lp.startTime, true, false)}
						</Text>
					</Group>
				</>
			);
		}
		else if (now > end)
		{
			//ended, withdraw / claim
			return (
				<>
					{admin}
					<Group className="launchpadEnded">
						<Text size="1">
							The Launchpad has ended.						
						</Text>
						<Text color="2">
							Claim your shares and withdraw.
						</Text>

						<Group className="choice">
							<Group className="claimAndWithdraw">
								<Text size="1" className="head">
									SOFTCAP:
								</Text>
								<Text color="2">
									Claim: {LWeb3.smartFormatTokensDisplay(lp.userClaimableSoft, lp.rewardToken, true)} {lp.rewardToken.symbol}
								</Text>
								<Text color="2">
									Withdraw: {LWeb3.smartFormatTokensDisplay(lp.userWithdrawableSoft, lp.depositToken, true)} {lp.depositToken.symbol}
								</Text>
								<Button
									buttonStyle={lp.userClaimedSoft || lp.userClaimedHard ? "0" : "1"}
									onClick={() => this.onClick_claimSoft()}>
									{lp.userClaimedSoft ? "claimed" : (lp.userClaimedHard ? "disabled" : "Claim")}
								</Button>
							</Group>

							<Group className="claimOversubscribed">						
								<Text size="1" className="head">
									HARDCAP:
								</Text>
								<Text color="2">
									Claim: {LWeb3.smartFormatTokensDisplay(lp.userClaimableHard, lp.rewardToken, true)} {lp.rewardToken.symbol}
								</Text>
								<Text color="2">
									Withdraw: {LWeb3.smartFormatTokensDisplay(lp.userWithdrawableHard, lp.depositToken, true)} {lp.depositToken.symbol}
								</Text>
								<Button
									buttonStyle={lp.userClaimedSoft || lp.userClaimedHard ? "0" : "1"}
									onClick={() => this.onClick_claimHard()}>
									{lp.userClaimedHard ? "claimed" : (lp.userClaimedSoft ? "disabled" : "Claim")}
								</Button>
							</Group>
						</Group>
					</Group>
				</>
			);
		}
		else
		{
			//active, deposit
			return (
				<>
					{admin}
					<Group className="launchpadStarted">
						<Text size="1">
							The Launchpad has started.						
						</Text>
						<Text color="2">
							Deposit your funds. Launchpad will end at {LLib.formatDate(new Date(end * 1000), true, false)}
						</Text>

						<Group className="deposit">
							<Text size="1" className="head">
								Deposit:
							</Text>
							<InputTokenAmount
								ref={this.refInputDeposit}
								token={lp.depositToken}	/>
							<Text color="2">
								User Deposit: {LWeb3.smartFormatTokensDisplay(lp.userDeposit, lp.depositToken, true)} {lp.depositToken.symbol}
							</Text>
							<Button buttonStyle="1" onClick={() => this.onClick_deposit()}>
								{lp.approved ? "Deposit" : "Enable"}
							</Button>
						</Group>

						<Group className="choice">
							<Group className="claimAndWithdraw">
								<Text size="1" className="head">
									SOFTCAP:
								</Text>
								<Text color="2">
									Claim: {LWeb3.smartFormatTokensDisplay(lp.userClaimableSoft, lp.rewardToken, true)} {lp.rewardToken.symbol}
								</Text>
								<Text color="2">
									Withdraw: {LWeb3.smartFormatTokensDisplay(lp.userWithdrawableSoft, lp.depositToken, true)} {lp.depositToken.symbol}
								</Text>
								<Button buttonStyle="0" onClick={() => this.onClick_claimDuringActive()}>
									Claim
								</Button>
							</Group>

							<Group className="claimOversubscribed">						
								<Text size="1" className="head">
									HARDCAP:
								</Text>
								<Text color="2">
									Claim: {LWeb3.smartFormatTokensDisplay(lp.userClaimableHard, lp.rewardToken, true)} {lp.rewardToken.symbol}
								</Text>
								<Text color="2">
									Withdraw: {LWeb3.smartFormatTokensDisplay(lp.userWithdrawableHard, lp.depositToken, true)} {lp.depositToken.symbol}
								</Text>
								<Button buttonStyle="0" onClick={() => this.onClick_claimDuringActive()}>
									Claim
								</Button>
							</Group>
						</Group>
					</Group>
				</>
			);
		}
	}

	renderPanelLaunchpad()
	{
		const lp = window.chef.launchpad;
		if (lp === null
			|| !lp.initialized)
		{
			return (			
				<Group className="PageLaunchpad">
					<Panel className="Panel_LaunchpadHeader">
						<Group className="header">
							<Text size="2">
								Loading...
							</Text>
						</Group>
					</Panel>
				</Group>
			);
		}
		const soft = LWeb3.smartFormatTokensDisplay(lp.softCap, lp.depositToken, true);
		const hard = LWeb3.smartFormatTokensDisplay(lp.hardCap, lp.depositToken, true);
		const softDecimal = parseFloat(window.chef.toBN(lp.softCap).div(lp.depositToken.one).toString());
		const hardDecimal = parseFloat(window.chef.toBN(lp.hardCap).div(lp.depositToken.one).toString());
		const valueDecimal = parseFloat(window.chef.toBN(lp.totalDeposit).div(lp.depositToken.one).toString());
		const tab = <><br />&nbsp;&nbsp;&nbsp;&nbsp;</>
		return (			
			<Group className="PageLaunchpad">
				<Panel className="Panel_LaunchpadHeader">
					<Group className="header">
						<Text size="2">
							Launchpad
						</Text>
						<Text color="2" size="1">
							V2 Funding
						</Text>
					</Group>

					<Group className="info">
						<Text color="2">
							We currently want to fund our V2 development.
							For this we made this "tiered oversubsciption" Launchpad.
							Click <Link href="https://medium.com/@MoonVault/moonvault-funding-round-deb6e1ffdac1">[here]</Link> to read more about our Funding and the Launchpad.

							<br />							
							<br />
							- Softcap <Text size="-1">(fund V2 development)</Text>
								{tab}- $20k to hire FrontEnd Devs
								{tab}- $20k to hire Smart Contract Devs
							<br />
							<br />
							- Hardcap <Text size="-1">(optional, when product is finished, funding at ~0.65$ / MOON)</Text>
								{tab}- $35k for Protocol owned Liquidity
								{tab}- $100k for multiple high quality Audits	
						</Text>						
					</Group>

					<Text className="funded" color="2" size="1">
						Funded: {LWeb3.smartFormatTokensDisplay(lp.totalDeposit, lp.depositToken, true)} {lp.depositToken.symbol}
					</Text>

					<ProgressBar
						min={0}
						max={hardDecimal}
						value={valueDecimal}
						yellowStart={0}
						greenStart={softDecimal}
						showLabels={true}
						
						labelYellow="Soft Cap:"
						textYellow={<>{soft} {lp.depositToken.symbol}</>}
						labelGreen="Hard Cap:"
						textGreen={<>{hard} {lp.depositToken.symbol}</>}
						infoText={<>Price: {LWeb3.smartFormatTokensDisplay(lp.price, lp.depositToken, true)} {lp.depositToken.symbol} / {lp.rewardToken.symbol}</>} />
				</Panel>

				<Panel className="Panel_LaunchpadContent">
					{this.renderDepositOrWithdraw()}
				</Panel>			
				
			</Group>
		);
	}

    render()
    {
		if (!window.chef.moonChef?.isValidChain())
		{
			return this.renderPanelWrongChain()
		}

        return (
			<Group className="PageLaunchpad">
				{this.renderPanelLaunchpad()}
			</Group>
		)
    }
}

export default PageLaunchpad;