//libs
import {LLib} from './../libs/LLib';
import {LWeb3} from './../libs/LWeb3';

//contracts
import {ABI_VaultChef} from './../contracts/VaultChef';

class VaultChef
{
	constructor(_address)
	{	
		//init
		this.address = _address
		this.initialized = false

		//singleton
		window.vaultChef = this

		//values
		this.versionString = "0.0"
		this.version = LLib.getVersion(this.versionString)
		this.percentFactor = 100000
		
		//data
		this.compoundRewardFee = 0
		this.nativeLiquidityFee = 0
		this.nativePoolFee = 0	
		this.totalProfitFee = 0	
		this.depositFee = 0
		this.withdrawFee = 0		
		this.referredBy = ""
		this.referrals = 0

		//roles
		this.role_superAdmin = "0x0d6cceaa37e5e7618474d8eb3448c6d3f2360829c16d7bd3a0564a84b4ba3d58"
		this.role_admin = "0x2172861495e7b85edac73e3cd5fbb42dd675baadf627720e687bcfdaca025096"
		this.role_manager = "0xf206625bad3d9112d5609b8d356e6fbd514cd1f69980d4ce2b3e6e68e1789ace"
		this.hasRole_superAdmin = false
		this.hasRole_admin = false
		this.hasRole_manager = false

		//db
		this.dailyAPR = 0
		this.db =
		{
			syncBlock: 0,
		}
		this.last =
		{
			syncBlock: 0
		}
	}
	
	debugErrorString(_text)
	{
		return 'VaultChef failed at: ' + _text		
	}

	getContract(_user)
    {       
        let web3 = window.chef.selectWeb3Connection(_user)
        let con = new web3.eth.Contract(ABI_VaultChef, this.address)
        return con
    }
	
	async init()
	{	
		if (this.initialized)
		{
			return
		}

		//make multicall
        let mc = window.chef.makeMultiCall(false)
        let con = this.getContract()
        let calls =
        [ 
            {
				versionString: con.methods.VERSION(),
				percentFactor: con.methods.PERCENT_FACTOR(),				
                compoundRewardFee: con.methods.compoundRewardFee(),
                nativeLiquidityFee: con.methods.nativeLiquidityFee(),
                nativePoolFee: con.methods.nativePoolFee(),
				withdrawFee: con.methods.withdrawalFee()
            }
        ]

		//handle result
        const [ret] = await LWeb3.tryMultiCall(mc, calls, this.debugErrorString("init"), "VaultChef: init")
        const res =  ret[0]		
		this.versionString = res.versionString
        this.percentFactor = parseInt(res.percentFactor)
        this.compoundRewardFee = parseFloat(res.compoundRewardFee) / this.percentFactor
        this.nativeLiquidityFee = parseFloat(res.nativeLiquidityFee) / this.percentFactor
		this.nativePoolFee = parseFloat(res.nativePoolFee) / this.percentFactor
		this.withdrawFee = parseFloat(res.withdrawFee) / this.percentFactor

		//process
		this.version = LLib.getVersion(this.versionString)
		this.totalProfitFee = this.compoundRewardFee + this.nativeLiquidityFee + this.nativePoolFee

		//complete
		this.initialized = true
	}

	async reloadUserData()
	{	
		await this.init()
		if (!this.initialized
			|| window.chef.account === null)
		{
			return
		}

		//make multicall
        let mc = window.chef.makeMultiCall(true)
        let con = this.getContract(true)
        let calls =
        [ 
            {
				referralInfo: con.methods.getReferralInfo(window.chef.account),

				hasRole_superAdmin: con.methods.hasRole(this.role_superAdmin, window.chef.account),
				hasRole_admin: con.methods.hasRole(this.role_admin, window.chef.account),
				hasRole_manager: con.methods.hasRole(this.role_manager, window.chef.account),
            }
        ]

		//handle result
        const [ret] = await LWeb3.tryMultiCall(mc, calls, this.debugErrorString("userData"), "VaultChef: userData")
        const res =  ret[0]		
		this.referredBy = res.referralInfo[0]
        this.referrals = parseInt(res.referralInfo[1])
		this.hasRole_superAdmin = res.hasRole_superAdmin
		this.hasRole_admin = res.hasRole_admin
		this.hasRole_manager = res.hasRole_manager
	}

	////////////////////////////////////

	checkRole_SuperAdmin()
	{
		return this.hasRole_superAdmin
	}

	checkRole_Admin()
	{
		return (this.checkRole_SuperAdmin()
			|| this.hasRole_admin)
	}

	checkRole_Manager()
	{
		return (this.checkRole_Admin()
			|| this.hasRole_manager)
	}

	check_isTeam()
	{
		return (this.checkRole_Manager())
	}

	////////////////////////////////////

	async checkVaultApproved(_id)
	{
		let con = this.getContract(true)
		return (await LWeb3.tryCall(con.methods.checkVaultApproved(_id, window.chef.account), this.debugErrorString("checkApproved"), false) !== false)
	}
	
	async compound(_id, _description)
	{
		let con = this.getContract(true)
		await window.chef.trySend(con.methods.compound(_id), window.chef.account, this.debugErrorString("compound"), undefined, _description)
	}
	
	async withdrawAll(_id, _description)
	{
		await this.withdraw(_id, window.chef.web3_data.eth.abi.encodeParameter("int256", "-1"), _description)
	}
	
	async withdraw(_id, _amount, _description)
	{
		let con = this.getContract(true)
		await window.chef.trySend(con.methods.withdraw(_id, _amount), window.chef.account, this.debugErrorString("withdraw"), undefined, _description)
	}
	
	async deposit(_id, _amount, _description)
	{
		let con = this.getContract(true)
		await window.chef.trySend(con.methods.deposit(_id, _amount), window.chef.account, this.debugErrorString("deposit"), undefined, _description)
	}

	async setReferrer(_referrer)
	{
		let con = this.getContract(true)
		await window.chef.trySend(con.methods.setReferrer(_referrer), window.chef.account, this.debugErrorString("setReferrer"), undefined, `Set ${_referrer} as referrer`)
	}

	////////////////////////////////////
}

export default VaultChef;