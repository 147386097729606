//libs
import {LLib} from '../libs/LLib.js'
import {LWeb3} from '../libs/LWeb3.js'

//classes
import Cache from './Cache'
import Token from './Token'

//contracts
import {ABI_MoonChef} from '../contracts/MoonChef'

class MoonChef
{
	////////////////////////////////////

	constructor(_address)
	{
		//init
		this.initialized = false
		
		//values
		this.chainID = window.chef.moonConfig.moonChain
		this.address = window.chef.moonConfig.moonChef
		this.versionString = "0.0"
		this.version = LLib.getVersion(this.versionString)
		this.percentFactor = 1000000
		
		//data
		this.moonToken = null
		this.peggedToken = null		
		this.moonTokenAddress = ""
		this.peggedTokenAddress = ""
		this.minBuyPrice = "0"
		this.buyPriceMultiplicator = 0	
		this.buyPriceFee = 0
		this.feeAddress = ""
		this.mintableSupply = "0"
		this.totalMinted = "0"
		this.emissionPerDay = "0"
		this.totalSupply = "0"
		this.burnedSupply = "0"
		this.circulatingSupply = "0"
		this.availableSupply = "0"
		this.burnedSupply = "0"
		this.totalBalance = "0"
		this.tokenValue = "0"
		this.tokenPrice = "0"
		this.wrappedBalance = "0"
		this.wrappedBalanceUSD = "0"

		if (!this.isValidChain())
		{
			this.moonToken = new Token(
			{
				symbol: "MOON",
				icon: "./assets/tokens/MOON.png"
			})
		}

		//cache
		this.cache_liquidityData = new Cache(async (_p) => await this.db_getLiquidityData(_p, true), 60)

		//db
		this.db =
		{
			syncBlock: 0,
			originBlock: 0
		}
		this.last =
		{
			syncBlock: 0,
		}
	}

	////////////////////////////////////
	
	debugErrorString(_text)
	{
		return "MoonChef failed at: " + _text		
	}

	getContract(_user)
    {       
        let web3 = window.chef.selectWeb3Connection(_user)
        let con = new web3.eth.Contract(ABI_MoonChef, this.address)
        return con
    }

	isValidChain()
	{
		return (window.chef.currentChain?.id === this.chainID)
	}

	////////////////////////////////////

	async init()
	{
		if (this.initialized
			|| !this.isValidChain())
		{
			return
		}

		//make multicall
        let mc = window.chef.makeMultiCall(false)
        let con = this.getContract()
        let calls =
        [ 
            {
				percentFactor: con.methods.PERCENT_FACTOR(),
				versionString: con.methods.VERSION(),
                moonTokenAddress: con.methods.moonToken(),
                peggedTokenAddress: con.methods.peggedToken(),
                minBuyPrice: con.methods.minBuyPrice(),
				feeAddress: con.methods.feeAddress(),
				emissionPerDay: con.methods.emissionPerDay(),
				buyPriceFee: con.methods.buyPriceFee(),
				buyPriceMultiplicator: con.methods.buyPriceMultiplicator()
            }
        ]
		
		//handle result
        const [ret] = await LWeb3.tryMultiCall(mc, calls, this.debugErrorString("init"), "MoonChef: init")
        const res = ret[0]
		this.percentFactor			= parseInt(res.percentFactor)
        this.versionString 			= res.versionString
		this.moonTokenAddress 		= res.moonTokenAddress;	
		this.peggedTokenAddress 	= res.peggedTokenAddress
		this.minBuyPrice			= res.minBuyPrice
		this.buyPriceMultiplicator	= parseFloat(res.buyPriceMultiplicator) / this.percentFactor
		this.buyPriceFee			= parseFloat(res.buyPriceFee) / this.percentFactor
		this.feeAddress				= res.feeAddress
		this.emissionPerDay			= res.emissionPerDay

		//process
		this.version = LLib.getVersion(this.versionString)
		this.moonToken = new Token(
		{
			symbol: "MOON",
			contract: this.moonTokenAddress,
			icon: "./assets/tokens/MOON.png"
		})
		this.peggedToken = window.chef.findToken(this.peggedTokenAddress)		
		window.chef.addDepositToken(this.peggedToken)
		window.chef.addDepositToken(this.moonToken)
		if (window.chef.findToken(this.moonToken.address) === null)
		{
			window.chef.tokens.push(this.moonToken)
		}

		//complete
		this.initialized = true
	}

	async reloadData()
	{
		//lazy init
		this.init()
		if (!this.initialized
			|| !this.isValidChain())
		{
			return
		}

		//make multicall
        let mc = window.chef.makeMultiCall(false)
        let con = this.getContract()
        let calls =
        [ 
            {
				totalSupply: con.methods.totalSupply(),
                circulatingSupply: con.methods.circulatingSupply(),
                availableSupply: con.methods.availableSupply(),
				burnedSupply: con.methods.burnedSupply(),
				mintableSupply: con.methods.mintableSupply(),
                totalBalance: con.methods.totalBalance(),
				wrappedBalance: con.methods.wrappedBalance(),
				tokenValue: con.methods.tokenValue(),
				tokenPrice: con.methods.tokenPrice(),				
				totalMinted: con.methods.totalMinted(),  
            }
        ]

		//handle result
        const [ret] = await LWeb3.tryMultiCall(mc, calls, this.debugErrorString("reloadData"), "MoonChef: reloadData")
        const res = ret[0]
		this.totalSupply			= res.totalSupply
		this.circulatingSupply 		= res.circulatingSupply;	
		this.availableSupply 		= res.availableSupply
		this.burnedSupply			= res.burnedSupply
		this.totalBalance			= res.totalBalance
		this.wrappedBalance			= res.wrappedBalance
		this.tokenValue				= res.tokenValue
		this.tokenPrice				= res.tokenPrice
		this.mintableSupply			= res.mintableSupply		
		this.totalMinted			= res.totalMinted

		//process
		this.wrappedBalanceUSD = await window.chef.wrappedCoin.getPriceUSDForAmount(this.wrappedBalance)

		//event
        document.dispatchEvent(new CustomEvent('moonChef_info'))
	}

	////////////////////////////////////

	async checkApprovedMoon()
	{
		return await this.moonToken.checkApproved(this.address)
	}

	async checkApprovedPegged()
	{
		return await this.peggedToken.checkApproved(this.address)
	}

	async buy(_amount)
    {
        let con = this.getContract(true)
        await window.chef.trySend(
            con.methods.buy(
                _amount),
            window.chef.account,
            this.debugErrorString("buy"))
    }

	async sell(_amount)
    {
        let con = this.getContract(true)
        await window.chef.trySend(
            con.methods.sell(
                _amount),
            window.chef.account,
            this.debugErrorString("sell"))
    }

	async swapForLiquidity(_token)
    {
        let con = this.getContract(true)
        await window.chef.trySend(
            con.methods.swapForLiquidity(
                _token.address),
            window.chef.account,
            this.debugErrorString("swapForLiquidity"))
    }

	////////////////////////////////////

	getPriceUSDForAmount(_amount)
    {
		const amount = window.chef.toBN(_amount)
        const circulatingSupply = window.chef.toBN(this.circulatingSupply)
		const totalBalance = window.chef.toBN(this.totalBalance)
	 	let balanceUSDValue = window.chef.toBN(0)
		if (circulatingSupply.cmp(window.chef.toBN(0)) !== 0)
		{
			balanceUSDValue = totalBalance.mul(amount).div(circulatingSupply)
		}
		const balanceUSD = balanceUSDValue.toString(10)
		return balanceUSD
	} 

	getBalanceUSD()
	{
		return this.getPriceUSDForAmount(this.moonToken.userBalance)
	}

	////////////////////////////////////

	async db_getLiquidityData(_filter, _forceReload)
	{
		_filter = _filter || {}

		if (_forceReload)
		{
			let data = undefined
			try
			{
				let apiURL = window.chef.api_url + "?module=token&action=getTokenLiquidity"
				apiURL += "&days=" + (_filter.days || 30)
				data = await LLib.fetchJSON(apiURL)
			}
			catch (e) { }

			return data
		}

		return await this.cache_liquidityData.getData(_filter)
	}

	////////////////////////////////////
}

export default MoonChef;